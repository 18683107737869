import { Box, useTheme } from "@mui/material"
import { InternalLink } from "@/common/CommonComponents"

export default function LogoButton() {
    const theme = useTheme()
    
    return (
        <InternalLink href="dashboard">
            <Box
                component="img"
                src={`/logo_${theme.palette.mode}.png`}
                sx={{ width: "3em", cursor: "pointer"}}
            />
        </InternalLink>
    )
}

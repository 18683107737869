import { useEffect, useState } from "react";
import { Helmet } from "react-helmet"

import { useTheme } from "@mui/material";
import { Grid, Skeleton, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { Heading } from "@/common/StyledComponents";
import { DottedSum } from "@/common/CommonComponents";
import { request } from "@/Api";
import { month_abbr, quarter_abbr } from "@/constants";

import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'; // markup
import DonutSmallOutlinedIcon from '@mui/icons-material/DonutSmallOutlined'; // avg margin
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'; // gp
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded'; // scheduled bills
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import { formattedCurrency } from "@/utils";

export default function Reports() {
    const [ compare, setCompare ] = useState(false)
    const [ reports, setReports ] = useState(null)
    const [ config, setConfig ] = useState([])
    const [ requesting, setRequesting ] = useState(false)

    const periods = config.period_type == "month" ? month_abbr : quarter_abbr

    const getReport = (config) => {
        setConfig(config)
        setRequesting(true)
        request.get("/reports", {params: config})
        .then((response) => {
            setReports(response.data)
            setRequesting(false)
            setConfig(response.data.config)
        })
    } 
    useEffect(()=>{
        getReport({group_type: "country", period_type: "month"})
    }, [])

    const handleToggleChange = (field) => (event, value) => {
        if (!value){
            return
        }
        getReport({...config, [field]: value})
    }

    const handleCompareFYToggleChange = (event, value) => {
        if (value) {
            setCompare(true)
        } else {
            setCompare(false)
        }
    }

    if (!reports || !periods){
        return null
    }

    if (requesting) {
        return (<ReportSkeleton />)
    }

    return (
        <>
            <Helmet><title>Reports - Makers Central</title></Helmet>

            <Grid container justifyContent={"space-between"} alignItems={"center"} marginBottom={4}>
                <Grid item>
                    <Heading style={{marginBottom: 30, paddingTop: 12}}>Reports</Heading>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            <ToggleButtonGroup
                                size="small"
                                color="primary"
                                value={compare ? "compare" : null}
                                exclusive
                                onChange={handleCompareFYToggleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="compare">Compare</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item>
                            <ToggleButtonGroup
                                size="small"
                                color="primary"
                                value={config.group_type}
                                exclusive
                                onChange={handleToggleChange("group_type")}
                                aria-label="Platform"
                            >
                                <ToggleButton value="country">Country</ToggleButton>
                                <ToggleButton value="client">Client</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item>
                            <ToggleButtonGroup
                                size="small"
                                color="primary"
                                value={config.period_type}
                                exclusive
                                onChange={handleToggleChange("period_type")}
                                aria-label="Platform"
                            >
                                <ToggleButton value="month">Month</ToggleButton>
                                <ToggleButton value="quarter">Quarter</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item md={3}>
                    <DottedSum
                        icon={InsertChartOutlinedRoundedIcon} color={"#27C77E"}
                        title={"Scheduled Bills"} subTitle={"Previous Period"}
                        amount={reports.grand_totals.scheduled_bills_total[0]} 
                        subAmount={reports.grand_totals.scheduled_bills_total[1]} 
                        currency={"CAD"}
                    />
                </Grid>
                <Grid item md={3}>
                    <DottedSum
                        icon={AccountBalanceWalletOutlinedIcon} color={"#27C77E"}
                        title={"Bid Renenue"} subTitle={"Previous Period"}
                        amount={reports.grand_totals.bid_revenue_total[0]} 
                        subAmount={reports.grand_totals.bid_revenue_total[1]} 
                        currency={"CAD"}
                    />
                </Grid>
                <Grid item md={3}>
                    <DottedSum
                        icon={DonutSmallOutlinedIcon} color={"#27C77E"}
                        title={"Overall Margin"} subTitle={"Previous Period"}
                        amount={reports.grand_totals.overall_margin[0]}
                        subAmount={reports.grand_totals.overall_margin[1]} 
                        currency={"CAD"} isPercentage
                    />
                </Grid>
                <Grid item md={3} paddingBottom={5}>
                    <DottedSum
                        icon={LocalOfferOutlinedIcon} color={"#27C77E"}
                        title={"Avg Markup"} subTitle={"Previous Period"}
                        amount={reports.grand_totals.overall_markup[0]} 
                        subAmount={reports.grand_totals.overall_markup[1]} 
                        currency={"CAD"} isPercentage
                    />
                </Grid>
            </Grid>

            <div style={{overflow: "scroll", fontSize: 12, textAlign: "right", paddingTop: 16}}>
                {Object.entries(reports.detailed_totals).map(group =>
                    <div key={group}>
                        <Grid container marginBottom={8} wrap="nowrap">
                            <Grid item width={80} marginRight={1} textAlign={"left"}>
                                <span style={{lineHeight: 2, fontWeight:"bold"}}>{group[0]}</span>
                            </Grid>
                            <Grid item width={"100%"} minWidth={0}>
                                {Object.entries(group[1]).map((measurement, index) =>
                                    <Grid container key={measurement[0]} wrap="nowrap">
                                        <PeriodRow 
                                            measurement={measurement[0]} 
                                            periodRow={measurement[1]} 
                                            index={index} compare={compare} 
                                            periods={periods}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                )}
            </div>
        </>
    )
}

const PeriodRow = ({measurement, periodRow, index, compare, periods}) => {
    const theme = useTheme()
    return (
        <>
            <Grid container>
                <Grid item minWidth={140} paddingRight={2} textAlign={"left"}>
                    <span style={{lineHeight: 2}}>{measurement}</span>
                </Grid>
                <Grid alignItems={"center"} container style={{
                    opacity: compare ? 1 : 0, 
                    height: compare ? "24px" : 0,
                    transition: "all 0.3s", 
                    color: "gray",
                }}>
                    <Grid item style={{lineHeight: 2}}>
                        Previous FY 
                    </Grid>
                    <Grid item>
                        <HistoryRoundedIcon fontSize="tiny" sx={{marginLeft: 0.3, opacity: 0.5}}/> 
                    </Grid>
                </Grid>
            </Grid>
            {periods.map((period, i) =>
                <Grid
                    container
                    item minWidth={80} width={"100%"} key={period}
                    sx={{background: index%2==0?theme.palette.background.paper:"auto"}}
                >
                    <Grid item md={12} position={"relative"}>
                        <span style={{position: "absolute", right: 0, top: -16, color: "grey"}}>
                            {index == 0 && period}
                        </span>
                        <span style={{lineHeight: 2}}>
                            {periodRow[period] ? formattedCurrency(periodRow[period]["0"], "CAD", true, 0) || 0 : 0}
                        </span>
                    </Grid>
                    <Grid item md={12} style={{
                        opacity: compare ? 1 : 0, 
                        height: compare ? "24px" : 0,
                        transition: "all 0.3s", 
                    }}>
                        <span style={{lineHeight: 2, color: "gray"}}>
                            {periodRow[period] ? formattedCurrency(periodRow[period]["1"], "CAD", true, 0) || 0 : 0}
                        </span>
                    </Grid>
                </Grid>
            )}
        </>
    )
}

const ReportSkeleton = () => {
    return (
        <>
            <Grid container spacing={2} height={200}>
            <Grid item md={3}><Skeleton height={"100%"}/></Grid>
            <Grid item md={3}><Skeleton height={"100%"}/></Grid>
            <Grid item md={3}><Skeleton height={"100%"}/></Grid>
            <Grid item md={3}><Skeleton height={"100%"}/></Grid>
            </Grid>
            <Skeleton height={30}/>
            <Skeleton height={30}/>
            <Skeleton height={30}/>
            <Skeleton height={30}/>
            <br /><br />
            <Skeleton height={30}/>
            <Skeleton height={30}/>
            <Skeleton height={30}/>
            <Skeleton height={30}/>
            <br /><br />
            <Skeleton height={30}/>
            <Skeleton height={30}/>
            <Skeleton height={30}/>
            <Skeleton height={30}/>
        </>
    )
}
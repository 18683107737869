import { Button, Grid, useTheme } from "@mui/material";
import ActualsGroup from "./ActualsGroup";
import React, { useState } from "react";
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { SearchBox } from "@/common/StyledComponents";
import { openBillsPopup, openLinePopup, searchLines, selectShowRunning, toggleRunning } from "./actualSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const ActualLinesWithHeader = ({bids, customLinesGroup, selectable}) => {
    const [ expandAll, setExpandAll ] = useState(false)
    const dispatch = useDispatch()

    const handleSearch = (event) => {
        const value = event.target.value
        dispatch(searchLines(value))
    }

    const setExpands = []
    function registerSetExpand (setExpand) {
        setExpands.push(setExpand)
    }

    const handleExpandAll = () => {
        setExpands.forEach(setExpand=>{
            setExpand(!expandAll)
        })
    }

    return (
        <div>
            <ActualLinesHeader
                expandAll={expandAll}
                setExpandAll={setExpandAll}
                handleSearch={handleSearch}
                handleExpandAll={handleExpandAll}
            />
            <ActualLines 
                bids={bids}
                registerSetExpand={registerSetExpand}
                customLinesGroup={customLinesGroup}
                selectable={selectable}
            />
        </div>
    )
}

export const ActualLines = ({bids, registerSetExpand, customLinesGroup, selectable}) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    return (
        <>
            {bids.map((bid, index)=>
                <div style={{marginBottom: 10}} key={index}>
                    <ActualsGroup
                        group={bid}
                        registerSetExpand={registerSetExpand}
                        selectable={selectable}
                    />
                </div>
            )}
            <div style={{marginBottom: 10}}>
                <ActualsGroup
                    group={customLinesGroup}
                    registerSetExpand={registerSetExpand}
                    selectable={selectable}
                />
            </div>
            {(customLinesGroup && customLinesGroup.line_items && customLinesGroup.line_items.length) == 0 &&
                <Button 
                    sx={{margin: 1}}
                    onClick={()=>{dispatch(openLinePopup())}}
                    variant="outlined" color={theme.palette.mode=="dark" ? "secondary" : "info"} 
                    startIcon={<AddCircleOutlineRoundedIcon/>}
                >
                    Add Actual Line
                </Button>
            }
        </>
    )
}

export const ActualLinesHeader = ({expandAll, setExpandAll, handleSearch, handleExpandAll}) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const showRunning = useSelector(selectShowRunning)

    const handleExpand = () => {
        setExpandAll(!expandAll)
        handleExpandAll()
    }

    const handleToggleRunning = () => {
        dispatch(toggleRunning())
    }

    return(
        <Grid 
            container justifyContent={"space-between"} 
            alignItems={"center"} paddingBottom={2} 
            position={"sticky"} top={-1} zIndex={1} padding={1}
            sx={{background: theme.palette.background.default}}
        >
            <Grid item>
                <Grid container alignItems={"center"} spacing={2}>
                    <Grid item>
                        <h4>Bids</h4>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleExpand} endIcon={expandAll ? <UnfoldLessIcon/> : <UnfoldMoreIcon />}>
                            {expandAll ? "Collapse all"  : "Expand all"}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleToggleRunning} endIcon={showRunning ? <UnfoldLessIcon style={{transform: "rotate(90deg)"}}/> : <UnfoldMoreIcon style={{transform: "rotate(90deg)"}}/>}>
                            {showRunning ? "Hide Running"  : "Show Running"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems={"center"} spacing={2}>
                    <Grid item>
                        <SearchBox handleSearch={handleSearch}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"

import { Button, Grid, useTheme, IconButton, Accordion, AccordionDetails, AccordionSummary, Modal, Switch, Table, TableBody, TableCell, TableRow, TextField, Card, CardActions, CardContent } from "@mui/material"

import { request } from "@/Api"
import { CustomBox, Heading } from "@/common/StyledComponents"
import EditIcon from '@mui/icons-material/Edit';
import BaseOrganizationComponents from "@/common/BaseOrganizationComponents"
import BaseAdmin from "@/admin/BaseAdmin"
import { selectHasStrategy } from "@/auth/authSlice"
import SyncButton from "@/admin/SyncButton"

export default function Admin() {
    const theme = useTheme()

    const [activeFinancialTerm, setActiveFinancialTerm] = useState(null)
    const [financialTerms, setFinancialTerms] = useState([])
    const [financialTermPopupOpen, setFinancialTermPopupOpen] = useState(false)
    
    const hasAccountingStrategy = useSelector((state) => selectHasStrategy(state, "accounting_strategy"))
    const hasResourcingStrategy = useSelector((state) => selectHasStrategy(state, "resourcing_strategy"))
    
    const tabs = ["General"]
    if (hasAccountingStrategy) {
        tabs.push("Accounting")
    }
    if (hasResourcingStrategy) {
        tabs.push("Resourcing")
    }
    tabs.push("Financial Terms")

    const authorizeQuickbooks = () => {
        request.get("quickbooks_authorize", { maxRedirects: 0 })
        .then(response => {
            window.location = response.data
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getFinancialTerms = () => {
        request.get("financial-terms")
        .then(response=>{
            setFinancialTerms(response.data)
        })
    }

    const handleOpenPopup = () => {
        setFinancialTermPopupOpen(true)
    }

    const handleClosePopup = () => {
        setFinancialTermPopupOpen(false)
        setActiveFinancialTerm(null)
    }

    const handleEditButton = (term) => () => {
        setActiveFinancialTerm(term)
        setFinancialTermPopupOpen(true)
    }

    const handleSwitchChange = (term) => () => {
        request.put(`/financial-terms/${term.id}`, {...term, public: !term.public})
        .then(response=>{
            setFinancialTerms(financialTerms.map(term=>term.id==response.data.id ? response.data : term))
        })
    }

    useEffect(()=>{
        getFinancialTerms()
    }, [])

    return (
        <BaseAdmin title="Admin" tabs={tabs}>
            <Helmet><title>Admin - Makers Central</title></Helmet>

            {/* General */}            
            <Grid container alignItems="center" justifyContent="flex-start" spacing={2} paddingBottom={3}>
                <SyncButton name="Update Signed Project Calculated Fields" route="/admin/update-all-sign-project-calculated-fields" notes="Recalculating project fields with properties"/>
                <SyncButton name="Update All Calculated Fields" route="/admin/update-all-calculated-fields" notes="Update all model's calculated fields with their properties"/>
                <SyncButton name="Sync Exchange Rates" route="/exchange-rates/refresh" notes="Update Exchange Rate table daily"/>
            </Grid>

            {/* Accounting */}
            {hasAccountingStrategy && (
                <Grid container spacing={3} paddingBottom={5}>    
                    <SyncButton name="Sync QB Invoices" route="/invoices/refersh-invoices-test" notes="Update Invoice table, add if missing, archive if no longer in QB response"/>
                    <SyncButton name="Sync QB Income Summary" route="/admin/refresh-customer-income-test" notes="Refresh project accounting numbers from QB (from invoice total)"/>
                    <SyncButton name="Sync QB Income Summary (TMP)" route="/admin/refresh-customer-income-tmp-test" notes="Refresh project accounting numbers from QB (from qb report)"/>
                    <SyncButton name="Sync Accounting Bills" route="/accounting-bills/refersh-accounting-bills" notes="Update Accounting Bills table by inserting any new QB bills"/>
                    <SyncButton name="Sync Accounting Bill Attachments" route="/accounting-bill-attachments/refersh" notes="Add Attachables if they are being newly created"/>
                    
                    <Grid item md={3} xs={6}>
                        <Card>
                            <CardContent>
                                <h3 style={{marginTop: "0"}}>Authorize Quickbooks</h3>
                                <p>Initialize the Quickbooks API key authorization flow.</p>
                            </CardContent>
                            <CardActions>
                                <Button variant="contained" onClick={authorizeQuickbooks}>Authorize</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            )}

            {/* Resourcing */}
            {hasResourcingStrategy && (
                <Grid container spacing={3} paddingBottom={5}>
                    <SyncButton name="Sync Projects Forecast Hours" route="/admin/refresh-projects-forecast-hours" notes="Update project hours count from Forecast (should be removed soon)"/>
                    <SyncButton name="Sync Project Float Actual" route="/admin/refresh-all-project-float-actual" notes="Update all project Internal Actuals from Float"/>
                    <SyncButton name="Sync All Float Projects" route="/admin/refresh-all-float-projects" notes="Correct Float projects, treating MC as source of truth"/>
                    <SyncButton name="Sync Float IDs" route="/admin/refresh-float-ids" notes="Update float ids in MC, will become less useful as data are initially migrated to float"/>
                </Grid>
            )}

            {/* Financial Terms */}
            <Accordion>
                <AccordionSummary>
                    <Grid container alignItems={"center"} spacing={3}>
                        <Grid item>
                            <h3>Financial Terms</h3>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={handleOpenPopup}>Add</Button>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container maxHeight={500} overflow={"auto"}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Term Description</TableCell>
                                    <TableCell>Billing Schedule</TableCell>
                                    <BaseOrganizationComponents>
                                        <TableCell>Public</TableCell>
                                    </BaseOrganizationComponents>
                                    <TableCell></TableCell>
                                </TableRow>
                                {financialTerms.map((term, index)=>
                                    <TableRow key={index}>
                                        <TableCell>{term.name}</TableCell>
                                        <TableCell>{term.description}</TableCell>
                                        <TableCell>{term.billing_schedule}</TableCell>
                                        <BaseOrganizationComponents>
                                            <TableCell>
                                                <Switch checked={term.public} onChange={handleSwitchChange(term)}/>
                                            </TableCell>
                                        </BaseOrganizationComponents>
                                        <TableCell width={50}>
                                            <IconButton onClick={handleEditButton(term)}>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>

                    <FinancialTermPopup 
                        open={financialTermPopupOpen}
                        closePopup={handleClosePopup}
                        financialTerms={financialTerms}
                        setFinancialTerms={setFinancialTerms}
                        activeFinancialTerm={activeFinancialTerm}
                    />
                </AccordionDetails>
            </Accordion>
        </BaseAdmin>
    )
}

const FinancialTermPopup = ({open, closePopup, setFinancialTerms, financialTerms, activeFinancialTerm}) => {
    const theme = useTheme()
    const [financialTerm, setFinancialTerm] = useState({})
    const handleInputChange = (field) => (event) => {
        setFinancialTerm({...financialTerm, [field]: event.target.value})
    }
    // const setProjectTypeId = (value) => {
    //     setFinancialTerm({...financialTerm, "project_type_id": value})
    // }
    const addFinancialTerm = () => {
        request.post(`/financial-terms`, { 
            is_custom: false,
            ...financialTerm
        }).then(response=>{
            closePopup()
            setFinancialTerms([...financialTerms, response.data])
        })
    }
    const updateFinancialTerm = () => {
        request.put(`/financial-terms/${activeFinancialTerm.id}`, {
            ...financialTerm
        }).then(response=>{
            closePopup()
            setFinancialTerms(financialTerms.map(term=>term.id == response.data.id ? response.data : term))
        })
    }
    useEffect(()=>{
        setFinancialTerm(activeFinancialTerm || {})
    }, [open, activeFinancialTerm])
    return (
        <Modal open={open} onClose={closePopup}>
            <CustomBox style={{background: theme.palette.background.paper, overflow: "auto"}}>
                <Heading>{`${activeFinancialTerm ? "Update" : "Add"} Financial Term`}</Heading>
                <h3>Name</h3>
                <TextField 
                    fullWidth
                    onChange={handleInputChange("name")}
                    value={financialTerm.name || ""}
                    size="small"
                />
                <h3>Term description</h3>
                <TextField 
                    fullWidth
                    multiline
                    rows={5}
                    onChange={handleInputChange("description")}
                    value={financialTerm.description || ""}
                    size="small"
                />
                <h3 style={{marginBottom: 0}}>Billing schedule</h3>
                <span style={{fontSize: 12, lineHeight: 2.5, color: "grey"}}>example: 60/30/10</span><br/>
                <TextField 
                    sx={{paddingBottom: 3}}
                    fullWidth
                    onChange={handleInputChange("billing_schedule")}
                    value={financialTerm.billing_schedule || ""}
                    size="small"
                />
                {/* <h3>Project Type</h3>
                <ApiAutocomplete
                    size={"small"}
                    sx={{width: "100%", paddingBottom: 3}}
                    value={financialTerm.project_type_id}
                    apiRoute={"project-types"}
                    fields={["name"]}
                    onChange={(e, v)=>{setProjectTypeId(v)}}
                /> */}
                <Button variant="contained" onClick={activeFinancialTerm ? updateFinancialTerm : addFinancialTerm}>
                    {`${activeFinancialTerm ? "Update" : "Create"}`}
                </Button>
            </CustomBox>
        </Modal>
    )
}

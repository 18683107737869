import { useState } from 'react';

import IconButton from '@mui/material/IconButton';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { BID_STATUS_SIGNED, BID_STATUS_DRAFT, BID_STATUS_APPROVED, BID_STATUS_PENDING_SIGNATURE } from "@/bid/constants";
import DeleteRowButton from '@/common/DeleteRowButton';
import BidCopyPopup from '@/bids/BidCopyPopup';
import { Tooltip, useTheme } from '@mui/material';
import FilesUploader from '@/common/FilesUploader';
import { Modal } from '@mui/material';
import { CustomBox } from '@/common/StyledComponents';
import { InternalLink } from '@/common/CommonComponents';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import StrategyComponents from '@/common/StrategyComponents';
import { ACCOUNTING_STRATEGY } from '@/constants';

export default function BidRowButtons({bid, handleDelete, readOnly, openTopsheet, handleBidFileUploaded}){
    const theme = useTheme()
    const [ toCopyBid, setToCopyBid ] = useState(null)
    const [ toViewFileBid, setToViewFileBid ] = useState(null)

    const handleCopyBid = (bid) => () => {
        setToCopyBid(bid)
    }

    const confirmed = [
        BID_STATUS_APPROVED,
        BID_STATUS_PENDING_SIGNATURE,
        BID_STATUS_SIGNED
    ].includes(bid.status)

    const onFileUploadedCallback = (files) => {
        setToViewFileBid(null)
        handleBidFileUploaded(bid.id, files)
    }

    return readOnly ? (
        <Tooltip title={confirmed ? null : "can't preview until confirmed"}>
            <span>
                <IconButton disabled={!confirmed} onClick={openTopsheet}>
                    <VisibilityIcon />
                </IconButton>
            </span>
        </Tooltip>
    ) : (
        <>
            {bid.status == BID_STATUS_SIGNED ? (
                <>
                    <IconButton target="_blank" href={bid.files[0]?.url} disabled={bid.files.length == 0}>
                        <PictureAsPdfRoundedIcon />
                    </IconButton>
                    <StrategyComponents strategy={ACCOUNTING_STRATEGY}>
                        <IconButton href={`/projects/${bid.project_id}/actuals`}>
                            <FactCheckIcon />
                        </IconButton>
                    </StrategyComponents>
                </>
            ) : null}

            {bid.status == BID_STATUS_APPROVED &&
                <>
                    <IconButton onClick={()=>{setToViewFileBid(bid)}}>
                        <FileUploadIcon />
                    </IconButton>
                    {toViewFileBid &&
                        <Modal open={toViewFileBid.id == bid.id} onClose={()=>setToViewFileBid(null)}>
                            <CustomBox sx={{background: theme.palette.background.paper, borderRadius: 3}}>
                                <FilesUploader
                                    title={`Upload Signed Estimate for ${bid.name}`}
                                    files={bid.files}
                                    modelType={"bid"}
                                    modelId={bid.id}
                                    onUploadedCallback={onFileUploadedCallback}
                                />
                            </CustomBox>
                        </Modal>
                    }
                </>
            }

            <IconButton onClick={handleCopyBid(bid)}>
                <ContentCopyIcon />
            </IconButton>

            {bid.status == BID_STATUS_DRAFT ? 
                <>
                    <DeleteRowButton handleDeleteConfirm={handleDelete}/>
                    <InternalLink href={`/bids/${bid.id}`}>
                        <IconButton>
                            <ArrowCircleRightIcon />
                        </IconButton>
                    </InternalLink>
                </> 
                : 
                <InternalLink href={`/bids/${bid.id}`}>
                    <IconButton>
                        <VisibilityIcon />
                    </IconButton>
                </InternalLink>
            }

            <BidCopyPopup  
                bid={toCopyBid}
                setBid={setToCopyBid}
            />
        </>
    )
}


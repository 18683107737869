import { Avatar, Grid, useTheme, IconButton } from "@mui/material"
import { useState } from "react"
import { MakersIcon, InternalLink } from "@/common/CommonComponents"
import VerifiedIcon from "@mui/icons-material/Verified"
import { CustomChip } from "@/profiles_common/CommonComponents"
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import { DottedDiv } from "@/common/StyledComponents"

export default function PersonProfileThumbnail({personProfile}){
    const [ isHovering, setIsHovering ] = useState(false)
    const theme = useTheme()
    const image = personProfile.image
    const bannerImage = personProfile.banner_image
    const profilePicUrl = image ? (image.processed ? image.url_small : image.url_orig) : null
    const bannerPicUrl = bannerImage ? (bannerImage.processed ? bannerImage.url_medium : bannerImage.url_orig) : null
    return (
        <Grid item md={3} xs={6} >
            <InternalLink href={`/profile/${personProfile.handle}`} sx={{color: "inherit", textDecoration: "none"}} color="primary">
                <div
                    onMouseOver={()=>{setIsHovering(true)}}
                    onMouseLeave={()=>{setIsHovering(false)}}
                    style={{
                        overflow:"visible",
                        width: "100%", height: "100%", background: theme.palette.background.default,
                        borderRadius: 10, boxShadow: `0px 0px ${isHovering ? "0px" : "30px"} rgba(136, 136, 136, 0.1)`,
                        textAlign: "center", transition: "all 0.3s", cursor: "pointer"
                    }}
                >
                    <div style={{paddingBottom: 30}}>
                        <div style={{height: 30}}>
                            <DottedDiv backgroundColor={theme.palette.background.rainbow} borderRadius={"10px 10px 0px 0px"} dotColor={"#ffffff22"}>
                                <div style={{height: 75, overflow: "hidden"}}>
                                    {bannerPicUrl && <img src={bannerPicUrl} style={{height: "100%", width: "100%", objectFit: "cover"}} />}
                                </div>
                            </DottedDiv>
                        </div>
                        <div style={{position: "relative", width: 86, height: 86, margin: "0 auto"}}>
                            <Avatar sx={{width: "100%", height: "100%"}} src={profilePicUrl}/>
                            <IconButton size="small" style={{
                                cursor: "default",
                                position: "absolute", width: 22, height: 22,
                                background: theme.palette.background.default,
                                border: `1px solid ${theme.palette.background.paper}`,
                                bottom: 1, right: 1, borderRadius: "50%"
                            }}>
                                {personProfile.is_from_makers ?
                                    <MakersIcon size={16}/> :
                                    <VerifiedIcon fontSize="tiny" color={personProfile.verified ? "success" : "inherit"}/>
                                }
                            </IconButton>
                        </div>
                        <h3 style={{margin: 0, paddingTop: 10, fontSize: 14}}>{`${personProfile.first_name} ${personProfile.last_name}`}</h3>
                        <p style={{margin: 4, color: "grey", fontSize: 12, paddingBottom: 15}}>{personProfile.title}</p>
                        { 
                            personProfile.location &&
                            <CustomChip chipColorInherit icon={PlaceRoundedIcon} text={personProfile.location.short_name} />
                        }
                    </div>
                </div>
            </InternalLink>
        </Grid>
    )
}
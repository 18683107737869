import { createSlice, createSelector, current } from '@reduxjs/toolkit'
import { request } from "@/Api"
import { BID_STATUS_DRAFT, BID_STATUS_APPROVED, BID_STATUS_SIGNED, BID_TYPE_MAIN, HOURS_PER_DAY } from "@/bid/constants"
import { setError } from "@/nav/navSlice"
import { topSheetColumns } from './TopSheetColumns'


const defaultWorkingGroup = {
    open: false,
    children: [],
    line_items: [],
}

export const bidSlice = createSlice({
    name: "bid",
    initialState: {
        loading: false,
        details: {},
        project: {},
        insightGoals: {
            producer_fee_quoted_goal: 0,
            gross_profit_goal: 0,
            net_profit_goal: 0,
            default_insurance: 0,
        },
        insights: {
            producer_fee_quoted_percentage: 0,
            producer_fee_quoted: 0,
            gross_profit_margin: 0,
            client_gross_profit: 0,
            net_profit_margin: 0,
            client_net_profit: 0,
            insurance: 0,
        },
        totals: {
            client_budget: 0,
            office_budget: 0,
            client_marked_up_fee: 0,
            office_marked_up_fee: 0,
            adjustment: 0,
            office_adjustment: 0,
            client_gross_profit: 0,
            office_gross_profit: 0,
            client_net_profit: 0,
            office_net_profit: 0,
            client_quoted: 0,
            office_quoted: 0,
        },
        
        sections: [
            // array of section IDs
        ],
        groups: {
            // groupId: {
            //     ...groupData,
            //     children: [ ...ids ],
            //     line_items: [ ...ids ],
            // },
        },
        lines: {
            // lineId: {
            //     ...lineData
            // },
        },
        searchValue: "",
        searchedLines: [],

        workingLine: {
            // lineId: 123,
            // field: "rate",
            // value: 100,
        },
        workingGroup: {
            ...defaultWorkingGroup
        },
        highlights: {
            // lineId: {
            //     personName: "Test 123",
            //     color: "red",
            //     field: "rate",
            // },
        },
        noHighlights: [
            // always empty, used to default to empty highlights without triggering a re-render
        ],
        addons: {},
        bills: [],
        comments: [],
        activity: [],
        files: [],
        project_rd_bid_ids: [],
    },
    reducers: {
        updateLoading: (state, action) => {
            state.loading = action.payload
        },
        updateBid: (state, action) => {
            const { project, insurance, agency_fee, comments, files, activity, bills, ...details } = action.payload
            
            state.project = project
            state.comments = comments
            state.activity = activity
            state.files = files

            details.top_sheet_sender_ids = details.top_sheet_senders.map((sender)=>{return sender.id})
            state.details = {
                ...details,
                officeCurrency: action.payload.project?.legal_entity.country.currency || "CAD",
                clientCurrency: action.payload.project?.currency,
            }
            if (state.workingLine && state.workingLine.field == "financial_term") {
                state.details.financial_term.description = state.workingLine.value
            }

            state.insightGoals = {
                producer_fee_quoted_goal: action.payload.producer_fee_quoted_goal,
                gross_profit_goal: action.payload.gross_profit_goal,
                net_profit_goal: action.payload.net_profit_goal,
                default_insurance: action.payload.default_insurance,
            }
        },
        updateDetails: (state, action) => {
            const { project, insurance, comments, activity, ...details } = action.payload
            state.details = {
                ...details,
                officeCurrency: (project ? project.legal_entity.country.currency : state.details.officeCurrency) || "CAD",
                clientCurrency: project ? project.currency : state.details.clientCurrency,
            }
        },
        updateName: (state, action) => {
            state.details.name = action.payload
        },
        updateIsChangeOrder: (state, action) => {
            state.details.is_change_order = action.payload
        },
        updateFinancialTerms: (state, action) => {
            const { financial_term, bills } = action.payload
            if(financial_term) {
                state.details.financial_term_id = financial_term.id
                state.details.financial_term = { ...financial_term }
            } else {
                state.details.financial_term_id = null
                state.details.financial_term = null
            }
            if(bills != undefined) {
                state.bills = [ ...bills ]
            }
        },
        updateBills: (state, action) => {
            if (state.workingLine.billId) {
                state.bills = action.payload.map(bill => bill.id == state.workingLine.billId ? {
                    ...bill,
                    [state.workingLine.field]: state.workingLine.value,
                } : bill)
            } else {
                state.bills = action.payload
            }
        },
        updateBill: (state, action) => {
            const updatedBill = action.payload
            state.bills = state.bills.map(bill => bill.id == updatedBill.id ? { ...updatedBill } : bill)
        },
        addBill: (state, action) => {
            state.bills = [...state.bills, {...action.payload}]
        },
        removeBill: (state, action) => {
            const index = action.payload
            state.bills = [
                ...state.bills.slice(0, index),
                ...state.bills.slice(index+1),
            ]
        },
        updateInsights: (state, action) => {
            const {
                producer_fee_quoted_percentage,
                producer_fee_quoted,
                gross_profit_margin,
                client_gross_profit,
                net_profit_margin,
                client_net_profit,
                insurance,
            } = action.payload
            state.insights = {
                producer_fee_quoted_percentage,
                producer_fee_quoted,
                gross_profit_margin,
                client_gross_profit,
                net_profit_margin,
                client_net_profit,
                insurance,
            }
        },
        updateTotals: (state, action) => {
            const {
                client_budget,
                office_budget,
                client_marked_up_fee,
                office_marked_up_fee,
                adjustment,
                office_adjustment,
                client_gross_profit,
                office_gross_profit,
                client_net_profit,
                office_net_profit,
                client_quoted,
                office_quoted,
            } = action.payload

            if (state.totals.client_quoted != client_quoted) {
                state.bills = state.bills.map(bill => bill.quoted_percentage > 0 ? ({
                    ...bill,
                    actual_amount: bill.quoted_percentage * client_quoted
                }) : bill)
            }

            state.totals = {
                client_budget,
                office_budget,
                client_marked_up_fee,
                office_marked_up_fee,
                adjustment,
                office_adjustment,
                client_gross_profit,
                office_gross_profit,
                client_net_profit,
                office_net_profit,
                client_quoted,
                office_quoted,
            }
        },
        updateLinesAndGroups: (state, action) => {
            state.sections = []
            state.lines = {}
            state.groups = {}
            action.payload.forEach(section => {
                state.sections.push(section.id)

                state.groups[section.id] = {
                    ...section,
                    children: section.children.map(category => category.id)
                }
                
                section.children.forEach(category => {
                    state.groups[category.id] = {
                        ...category,
                        line_items: category.line_items.map(line => {
                            state.lines[line.id] = line
                            return line.id
                        })
                    } 
                })
            })
        },
        highlightLines: (state, action) => {
            state.highlights = action.payload
        },
        newGroup: (state, action) => {
            state.workingGroup = {
                ...action.payload,
                open: true,
                children: [],
                line_items: [],
                popupType: "create"
            }
        },
        editGroup: (state, action) => {
            state.workingGroup = {
                ...action.payload,
                open: true,
                popupType: "override",
                lineGroupType: action.payload.parent_id ? "category" : "section",
            }
        },
        cancelWorkingGroup: (state) => {
            state.workingGroup = {...defaultWorkingGroup}
        },
        updateWorkingLine: (state, action) => {
            state.workingLine = action.payload
        },
        clearWorkingLine: (state) => {
            state.workingLine = {}
        },
        addSection: (state, action) => {
            const categories = [...action.payload.children]
            state.groups[action.payload.id] = action.payload
            state.sections.push(action.payload.id)
            state.groups[action.payload.id].children = []
            categories.forEach(category=>{
                state.groups[category.id] = category
                state.groups[action.payload.id].children.push(category.id)
                category.line_items.forEach(line=>{
                    state.lines[line.id] = line
                    state.groups[category.id].line_items.push(line.id)
                })
            })
            state.workingGroup = {...defaultWorkingGroup}
        },
        addCategory: (state, action) => {
            const lines = [...action.payload.line_items]
            state.groups[action.payload.id] = action.payload
            state.groups[action.payload.parent_id].children.push(action.payload.id)
            state.groups[action.payload.id].line_items = []
            lines.forEach(line=>{
                state.lines[line.id] = line
                state.groups[action.payload.id].line_items.push(line.id)
            })
            state.workingGroup = {...defaultWorkingGroup}
        },
        addLine: (state, action) => {
            state.lines[action.payload.id] = action.payload
            state.groups[action.payload.line_group_id].line_items.push(action.payload.id)
        },
        insertLine: (state, action) => {
            const { line, index } = action.payload
            state.lines[line.id] = line

            state.groups[line.line_group_id].client_quoted += line.client_quoted
            state.groups[state.groups[line.line_group_id].parent_id].client_quoted += line.client_quoted

            state.groups[line.line_group_id].line_items = [
                ...state.groups[line.line_group_id].line_items.slice(0, index),
                line.id,
                ...state.groups[line.line_group_id].line_items.slice(index).map((lineId, i) => {
                    state.lines[lineId].sort_order = index + 1 + i
                    return lineId
                }),
            ]
        },
        deleteSection: (state, action) => {
            const deletedGroupIds = [
                action.payload.id,
                ...action.payload.children,
            ]
            const groupsToKeep = Object.values(state.groups).filter(group => !deletedGroupIds.includes(group.id))
            state.groups = groupsToKeep.reduce((o, group) => ({ ...o, [group.id]: group}), {})

            state.sections = state.sections.filter(sectionId => sectionId != action.payload.id)
            state.sections.forEach((sectionId, index) => {
                state.groups[sectionId].sort_order = index
            })

            const linesToKeep = Object.values(state.lines).filter(line => !action.payload.children.includes(line.line_group_id))
            state.lines = linesToKeep.reduce((o, line) => ({ ...o, [line.id]: line}), {})
        },
        deleteCategory: (state, action) => {
            const { [action.payload.id]: deletedCategory, ...newGroups } = state.groups 
            state.groups = newGroups

            state.groups[deletedCategory.parent_id].client_quoted -= deletedCategory.client_quoted

            state.groups[deletedCategory.parent_id].children = state.groups[deletedCategory.parent_id].children.filter(categoryId => categoryId != deletedCategory.id)
            state.groups[deletedCategory.parent_id].children.forEach((categoryId, index) => {
                state.groups[categoryId].sort_order = index
            })

            const linesToKeep = Object.values(state.lines).filter(line => line.line_group_id != deletedCategory.id)
            state.lines = linesToKeep.reduce((o, line) => ({ ...o, [line.id]: line}), {})
        },
        deleteLine: (state, action) => {
            const { [action.payload.id]: deletedLine, ...newLines } = state.lines 
            state.lines = newLines

            state.groups[deletedLine.line_group_id].client_quoted -= deletedLine.client_quoted
            state.groups[state.groups[deletedLine.line_group_id].parent_id].client_quoted -= deletedLine.client_quoted

            state.groups[deletedLine.line_group_id].line_items = state.groups[deletedLine.line_group_id].line_items.filter(lineId => lineId != deletedLine.id)
            state.groups[deletedLine.line_group_id].line_items.forEach((lineId, index) => {
                state.lines[lineId].sort_order = index
            })
        },
        updateGroup: (state, action) => {
            state.workingGroup = {...defaultWorkingGroup}

            let groupStack = [action.payload]
            while (groupStack.length > 0){
                const group = groupStack.pop()
                
                state.groups[group.id] = {
                    ...group,
                    children: group.children.map(child => child.id),
                    line_items: group.line_items.map(line => line.id),
                }
                if (group.children.length > 0){
                    groupStack = groupStack.concat(group.children)
                }
                if (group.line_items.length > 0){
                    group.line_items.forEach(line => {
                        console.log(line)
                        state.lines[line.id] = line
                    })
                }
            }
        },
        updateGroupColumns: (state, action) => {
            const group = action.payload
            state.groups[group.id].topsheet_show = group.topsheet_show
            state.groups[group.id].topsheet_columns = group.topsheet_columns
        },
        updateLineCalculatedFields: (state, action) => {
            recalculateLineAndGroupFields(state, action)
        },
        updateLine: (state, action) => {
            state.workingGroup = {...defaultWorkingGroup}

            if (state.workingLine.lineId == action.payload.id) {
                state.lines[action.payload.id] = {
                    ...action.payload,
                    [state.workingLine.field]: state.workingLine.value,
                }
            } else {
                state.lines[action.payload.id] = action.payload
            }
            recalculateLineAndGroupFields(state, action)
        },
        updateAddons: (state, action) => {
            Object.values(action.payload).map(addon=>{
                state.addons[addon.id] = addon
            })
        },
        updateAddon: (state, action) => {
            if (state.addons[action.payload.id]){
                state.addons[action.payload.id] = action.payload
            }
        },
        searchLines: (state, action) => {
            state.searchValue = action.payload.toLowerCase()
            
            state.searchedLines = []
            state.searchedGroups = []
            
            Object.entries(state.lines).forEach(lineEntry => {
                const searched = lineEntry[1].name.toLowerCase().includes(state.searchValue)
                if (searched){
                    state.searchedLines.push(parseInt(lineEntry[0]))
                    state.searchedGroups.push(lineEntry[1].line_group_id)
                    state.searchedGroups.push(state.groups[lineEntry[1].line_group_id].parent_id)
                }
            })
        },
        sortSections: (state, action) => {
            const { sectionId, sourceIndex, destinationIndex } = action.payload
            state.sections.splice(sourceIndex, 1)
            state.sections.splice(destinationIndex, 0, sectionId)
            state.sections.forEach((id, index) => {
                state.groups[id].sort_order = index
            })
        },
        sortCategories: (state, action) => {
            const { categoryId, sourceId, sourceIndex, destinationId, destinationIndex } = action.payload
            state.groups[sourceId].children.splice(sourceIndex, 1)
            state.groups[destinationId].children.splice(destinationIndex, 0, categoryId)

            state.groups[sourceId].children.forEach((id, index) => {
                state.groups[id].sort_order = index
            })
            if (destinationId != sourceId) {
                state.groups[categoryId].parent_id = destinationId
                state.groups[destinationId].children.forEach((id, index) => {
                    state.groups[id].sort_order = index
                })
            }

            const categoryTotal = state.groups[categoryId].client_quoted
            state.groups[sourceId].client_quoted -= categoryTotal
            state.groups[destinationId].client_quoted += categoryTotal
        },
        sortLines: (state, action) => {
            const { lineId, sourceId, sourceIndex, destinationId, destinationIndex } = action.payload
            state.groups[sourceId].line_items.splice(sourceIndex, 1)
            state.groups[destinationId].line_items.splice(destinationIndex, 0, lineId)
            
            state.groups[sourceId].line_items.forEach((id, index) => {
                state.lines[id].sort_order = index
            })
            if (destinationId != sourceId) {
                state.lines[lineId].line_group_id = destinationId
                state.groups[destinationId].line_items.forEach((id, index) => {
                    state.lines[id].sort_order = index
                })
            }

            const lineTotal = state.lines[lineId].client_quoted
            state.groups[sourceId].client_quoted -= lineTotal
            state.groups[destinationId].client_quoted += lineTotal
            state.groups[state.groups[sourceId].parent_id].client_quoted -= lineTotal
            state.groups[state.groups[destinationId].parent_id].client_quoted += lineTotal
        },
        addActivity: (state, action) => {
            const { bid_status, bid_type, project_rd_bid_ids, ...activity } = action.payload
            state.details.status = bid_status
            state.activity = [activity, ...state.activity]
            state.project_rd_bid_ids = (bid_status == BID_STATUS_APPROVED && bid_type == BID_TYPE_MAIN) ? project_rd_bid_ids : []
        },
        addFiles: (state, action) => {
            state.files = state.files.concat(action.payload)
        },
        closeRdPopup: (state) => {
            state.project_rd_bid_ids = []
        },
        updateLegalEntity: (state, action) => {
            state.project.legal_entity = action.payload
        }
    },
})

export const {
    updateLoading,
    updateBid, updateName, updateIsChangeOrder, updateInsights, updateTotals,
    updateFinancialTerms, updateBills, updateBill, addBill, removeBill,
    updateLinesAndGroups, highlightLines,
    newGroup, editGroup, cancelWorkingGroup,
    updateWorkingLine, clearWorkingLine,
    addSection, addCategory, addLine, insertLine,
    deleteSection, deleteCategory, deleteLine,
    sortSections, sortCategories, sortLines,
    updateGroup, updateLine,
    searchLines, addActivity, updateAddons, updateAddon,
    updateGroupColumns, updateDetails, addFiles, updateLineCalculatedFields, closeRdPopup, updateLegalEntity,
} = bidSlice.actions


export const apiSortRequest = payload => (dispatch, getState) => {
    const state = getState()
    const { sourceId, destinationId, lineId } = payload
    let sourceEndpoint = "/line-groups/order"
    let destinationEndpoint = "/line-groups/order"
    let sourceIds = []
    let destinationIds = []
    
    if (lineId) {
        sourceEndpoint += `/${sourceId}`
        sourceIds = state.bid.groups[sourceId].line_items 
        
        if (sourceId != destinationId) {
            destinationEndpoint += `/${destinationId}`
            destinationIds = state.bid.groups[destinationId].line_items
        }
    }
    else if (sourceId) {
        sourceEndpoint += `/${sourceId}`
        sourceIds = state.bid.groups[sourceId].children
        
        if (sourceId != destinationId) {
            destinationEndpoint += `/${destinationId}`
            destinationIds = state.bid.groups[destinationId].children
        }    
    }
    else {
        sourceIds = state.bid.sections
    }            

    const requests = []            
    if (sourceIds.length > 0) {
        requests.push(request.put(sourceEndpoint, sourceIds))
    }
    if (destinationIds.length > 0) {
        requests.push(request.put(destinationEndpoint, destinationIds))
    }

    Promise.all(requests).catch(() => {
        dispatch(setError({ message: "Failed to save sort order" }))
    })  
}

export const selectLoading = (state) => state.bid.loading
export const selectSocketConnectionLost = (state) => state.bid.socketConnectionLost
export const selectProject = (state) => state.bid.project
export const selectDetails = (state) => state.bid.details
export const selectUpdatedAt = (state) => state.bid.details.updated_at
export const selectOfficeCurrency = (state) => state.bid.details.officeCurrency
export const selectClientCurrency = (state) => state.bid.details.clientCurrency
export const selectIdenticalOfficeAndClientCurrency = (state) => state.bid.details.officeCurrency == state.bid.details.clientCurrency
export const selectStatus = (state) => state.bid.details.status
export const selectReadOnly = (state) => state.bid.details.status != BID_STATUS_DRAFT
export const selectIsSigned = (state) => state.bid.details.status == BID_STATUS_SIGNED
export const selectIsTemplate = (state) => state.bid.details.project_id == null
export const selectTemplate = (state) => state.bid.details.bid_template
export const selectName = (state) => state.bid.details.name
export const selectXodoDocumentHash = (state) => state.bid.details.xodo_document_hash
export const selectInsightGoals = (state) => state.bid.insightGoals
export const selectInsights = (state) => state.bid.insights
export const selectInsurance = (state) => state.bid.insights.insurance
export const selectTotals = (state) => state.bid.totals
export const selectComments = (state) => state.bid.comments
export const selectFile = (state) => state.bid.files[state.bid.files.length - 1]
export const selectActivity = (state) => state.bid.activity
export const selectWorkingGroup = (state) => state.bid.workingGroup
export const selectSections = (state) => state.bid.sections
export const selectGroups = (state) => state.bid.groups
export const selectGroup = (state, groupId) => state.bid.groups[groupId]
export const selectLines = (state) => state.bid.lines
export const selectLine = (state, lineId) => state.bid.lines[lineId]
export const selectLineIsVisible = (state, lineId) => !state.bid.searchValue || state.bid.searchedLines.includes(lineId)
export const selectGroupIsVisible = (state, groupId) => !state.bid.searchValue || state.bid.searchedGroups.includes(groupId)
export const selectSearchValue = (state) => state.bid.searchValue
export const selectHighlights = (state) => state.bid.highlights
export const selectLineHighlights = (state, lineId) => state.bid.highlights[lineId] || state.bid.noHighlights
export const selectTopSheetHighlights = (state) => state.bid.highlights[-1] || state.bid.noHighlights
export const selectFinancialTerm = (state) => state.bid.details.financial_term
export const selectBills = (state) => state.bid.bills
export const selectClientQuoted = (state) => state.bid.totals.client_quoted
export const selectRdBidIds = (state) => state.bid.project_rd_bid_ids
export const selectIncludeZeroLines = (state) => state.bid.details.include_zero_lines
export const selectSignerCount = (state) => state.bid.details.signer_count

export const selectTopsheetExistingColumns = createSelector(
    [selectGroups], (groups) => {
        let mergedColumns = []
        Object.values(groups).forEach(group=>{
            const applicableColumns = group.topsheet_columns.filter(column=>{
                const columnObj = topSheetColumns.find(c=>c.field == column)
                return !columnObj.ignoredByRateTypes.includes(group.rate_type)
            })
            mergedColumns = mergedColumns.concat(applicableColumns)
        })
        let columnSet = new Set(mergedColumns)
        columnSet = Array.from(columnSet)
        return columnSet
    }
)

export const selectReconstructedLineItemGroups = createSelector(
    [selectGroups], (groups) => {
        const lineItemsGrouped = []
        Object.values(groups).forEach(group=>{
            if (!group.parent_id){
                let section = {...group}
                let categories = [...section.children]
                for (var i = 0; i < categories.length; i ++){
                    categories[i] = groups[categories[i]]
                }
                section.children = categories
                lineItemsGrouped.push(section)
            }
        })
        return lineItemsGrouped
    }
)

export const selectAddons = (state) => state.bid.addons
export const selectAddonIds = createSelector(
    [selectAddons, ()=>{}], (addons) => {
        return Object.keys(addons)
    }
)
export const selectAddon = (state, addonId) => state.bid.addons[addonId]

export const selectCategoryHighlights = createSelector(
    [selectLines, selectHighlights, (state, categoryId) => categoryId],
    (lines, highlights, categoryId) => {
        return Object.values(highlights).flat().filter(highlight => {
            if (highlight.lineId && lines[highlight.lineId]){
                return lines[highlight.lineId].line_group_id == categoryId
            }
        })
    }
)

export const selectSectionHighlights = createSelector(
    [selectLines, selectGroups, selectHighlights, (state, sectionId) => sectionId],
    (lines, groups, highlights, sectionId) => {
        return Object.values(highlights).flat().filter(highlight => {
            if (highlight.lineId && lines[highlight.lineId] && groups[lines[highlight.lineId].line_group_id]){
                return groups[lines[highlight.lineId].line_group_id].parent_id == sectionId
            }
        })
    }
)

export const selectGroupsByGroupId = createSelector(
    [selectGroups, (state, groupId) => groupId],
    (groups, groupId) => {
        return groupId ? Object.values(groups).filter(group => group.parent_id == groupId).concat([groups[groupId]]) : []
    }
)

export const selectLinesByGroupId = createSelector(
    [selectGroups, selectLines, (state, groupId) => groupId],
    (groups, lines, groupId) => {
        const childrenGroups = groupId ? Object.values(groups).filter(group => group.parent_id == groupId).concat([groups[groupId]]) : []
        const groupIds = childrenGroups.map(group => group.id)
        return Object.values(lines).filter(line => groupIds.includes(line.line_group_id))
    }
)
 
export const selectGroupsSubset = createSelector(
    [selectGroups, (state, groupIds) => groupIds],
    (groups, groupIds) => Object.values(groups).filter(group => groupIds.includes(group.id))
)
export const selectLinesSubset = createSelector(
    [selectLines, (state, lineIds) => lineIds],
    (lines, lineIds) => Object.values(lines).filter(line => lineIds.includes(line.id))
)
export const selectBidUpdate = createSelector(
    [selectDetails],
    (details) => {
        return {
            ...details,
        }
    }
)
export const selectBidFinancialTermsUpdate = createSelector(
    [selectDetails, selectBills],
    (details, bills) => {
        return {
            ...details,
            bills: [...bills],
        }
    }
)

export const selectBillValidations = createSelector(
    [selectBills, selectProject, selectClientQuoted],
    (bills, project, client_quoted) => (project.legacy_bills ? {
        billsLength: 1,
        hasDates: 1,
        hasActuals: 1,
        totalPercentage: 100,
    } : {
        billsLength: bills.length,
        hasDates: bills.some(bill => !bill.bill_date) ? 0 : 1,
        hasActuals: bills.some(bill => !bill.actual_amount) ? 0 : 1,
        actualMatchesQuoted: Math.round(bills.reduce((acc, bill) => acc + bill.actual_amount, 0)) == Math.round(client_quoted),
        totalPercentage: bills.some(bill => bill.quoted_percentage == 0) ? 100 : Math.round(bills.reduce((total, bill) => total + bill.quoted_percentage * 100, 0)),
    })
)

const recalculateLineAndGroupFields = (state, action) => {
    const line = state.lines[action.payload.id]
    state.lines[action.payload.id].vendor_rate = action.payload.vendor_rate

    const reCalculatedFields = [
        "client_quoted",
        "client_budget",
        "client_gross_profit",
    ]

    reCalculatedFields.forEach(field => {
        const change = -line[field] + action.payload[field]
        state.lines[action.payload.id][field] = action.payload[field]
        state.groups[line.line_group_id][field] += change
        state.groups[state.groups[line.line_group_id].parent_id][field] += change
    });
    
    // vvvvvvvvvv calculate change in markup vvvvvvvvvv
    const categoryId = action.payload.line_group_id
    let categoryLineCount = 0
    let sectionLineCount = 0
    let categoryLineMarkupTotal = 0
    let sectionLineMarkupTotal = 0
    Object.values(state.lines).forEach(line => {
        if (line.client_quoted == 0) {
            return
        }
        if(line.line_group_id==categoryId){
            categoryLineCount ++ 
            categoryLineMarkupTotal += line.markup
        }
        const sectionId = state.groups[categoryId].parent_id
        if(state.groups[sectionId].children.includes(line.line_group_id)){
            sectionLineCount ++
            sectionLineMarkupTotal += line.markup
        }
    });
    if (categoryLineCount > 0) state.groups[line.line_group_id].average_markup = categoryLineMarkupTotal / categoryLineCount
    if (sectionLineCount > 0) state.groups[state.groups[line.line_group_id].parent_id].average_markup = sectionLineMarkupTotal / sectionLineCount
    // ^^^^^^^^^^ calculate change in markup ^^^^^^^^^^
}

export function getLineItemRate(existingRate, lineItem, rateType) {
    if (!lineItem || !lineItem.vendor_rate) {
        return existingRate
    }

    switch(rateType) {
        case "Hours":
            return Math.round(lineItem.vendor_rate / HOURS_PER_DAY * 100) / 100
        case "Days":
        case "Percentage":
            return lineItem.vendor_rate
        case "Flat rate":
        default:
            return existingRate
    }
}

export default bidSlice.reducer


import { useState } from "react"
import { Helmet } from "react-helmet"

import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"

import LockResetIcon from '@mui/icons-material/LockReset';

import { requestSilent } from "@/Api"
import AuthGallery from "@/auth/AuthGallery"
import { AuthContainer, AuthSection, CenteredAuthForm, Heading, SubHeading, Footer } from "@/auth/StyledComponents"
import { InternalLink } from "@/common/CommonComponents"
import { GlobalStyle } from "@/themes"

import "$/makers-interactive-logo/index"

export default function ForgotPassword() {
    const theme = useTheme()
    const [ loading, setLoading ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const [ email, setEmail ] = useState("")
    const [ error, setError ] = useState("")
    const themeMode = localStorage.getItem("themeMode") || "light"
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    const forgotPassword = () => {
        setLoading(true)

        requestSilent.post("forgot-password", {email: email}).then((response) => {            
            setSuccess(true)
        }).catch(error => {
            setError(error?.response?.data?.detail || 'There was an error resetting your password, please contact an admin')
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <AuthContainer isMobile={isMobile}>
            <Helmet><title>Forgot Password - Makers Central</title></Helmet>
            <GlobalStyle theme={themeMode} />            

            <AuthSection isMobile={isMobile} $fill>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <makers-logo style={{display: "block", width: "150px"}} />
                    </Grid>
                    <Grid item>
                        <InternalLink href={`/sign-up`}>Create an account</InternalLink>
                    </Grid>
                </Grid>

                <CenteredAuthForm>
                    <Grid container direction="column" spacing={5}>
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <Heading>Forgot password?</Heading>
                            </Grid>
                            <Grid item>
                                <SubHeading>
                                    Enter your email address you used to register and we'll send you a link to reset your password.
                                </SubHeading>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Email"
                                type="email"
                                variant="filled"
                                fullWidth
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                                disabled={success}
                            />
                        </Grid>
                        <Grid item>
                            { !success ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{color: "white"}}
                                    endIcon={<LockResetIcon />}
                                    fullWidth
                                    onClick={forgotPassword}
                                    disabled={loading}
                                >
                                    Reset password
                                </Button>
                            ): (
                                <SubHeading>A reset password email was sent to the above address.</SubHeading>
                            )}
                        </Grid>
                        { error ? (
                            <Grid item>
                                <Alert variant="filled" severity="error">{ error }</Alert>
                            </Grid>
                        ) : null }
                    </Grid>
                </CenteredAuthForm>

                <Footer>
                    <InternalLink href={`/login`}>Back</InternalLink>
                </Footer>

            </AuthSection>

            <AuthSection isMobile={isMobile}>
                <AuthGallery isMobile={isMobile} condensedOverlay>
                    <h3>#MadeWithMakers</h3>
                </AuthGallery>
            </AuthSection>
        </AuthContainer>
    )
}

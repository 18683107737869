import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet"

import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import CircularProgress from '@mui/material/CircularProgress'
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"

import LockResetIcon from '@mui/icons-material/LockReset';

import { requestSilent } from "@/Api"
import AuthGallery from "@/auth/AuthGallery"
import { AuthContainer, AuthSection, CenteredAuthForm, Heading, SubHeading } from "@/auth/StyledComponents"
import { GlobalStyle } from "@/themes"
import { InternalLink } from "@/common/CommonComponents"

import "$/makers-interactive-logo/index"

export default function ResetPassword() {
    const theme = useTheme()
    const { token } = useParams()
    const [ verified, setVerified ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const [ password, setPassword ] = useState("")
    const [ password2, setPassword2 ] = useState("")
    const [ error, setError ] = useState("")
    const themeMode = localStorage.getItem("themeMode") || "light"
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    const resetPassword = () => {
        setError("")
        setLoading(true)

        if (password != password2) {
            setError("Passwords do not match")
            setLoading(false)
            return
        }

        requestSilent.post("reset-password", {token: token, password: password}).then(() => {            
            setSuccess(true)
        }).catch(error => {
            if (error?.response?.status == 404) {
                setError("Invalid password reset token")
            } else {
                setError(error?.response?.data?.detail[0].msg || 'There was an error reseting your password, please contact an admin')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)

        requestSilent.post("reset-password-validate", {token: token}).then(() => {            
            setVerified(true)
        }).catch(error => {
            setError("Password reset link is either invalid or expired. Please submit another request.")
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <AuthContainer isMobile={isMobile}>
            <Helmet><title>Reset Password - Makers Central</title></Helmet>
            <GlobalStyle theme={themeMode} />            

            <AuthSection isMobile={isMobile} $fill>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <makers-logo style={{display: "block", width: "150px"}} />
                    </Grid>
                    <Grid item>
                        <InternalLink href={`/login`}>Login</InternalLink>
                    </Grid>
                </Grid>

                {!verified ? (
                    <CenteredAuthForm>
                        <Grid container direction="column" spacing={5}>
                            <Grid item>
                                <Heading>Reset password</Heading>
                            </Grid>
                            { loading ? (
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                            ) : null }
                            { error ? (
                                <Grid item>
                                    <Alert variant="filled" severity="error">{ error }</Alert>
                                </Grid>
                            ) : null }
                        </Grid>
                    </CenteredAuthForm>
                ) : !success ? (
                    <CenteredAuthForm>
                        <Grid container direction="column" spacing={5}>
                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <Heading>Reset password</Heading>
                                </Grid>
                                <Grid item>
                                    <SubHeading>
                                        Enter a new secure password for your account below.
                                    </SubHeading>
                                </Grid>
                            </Grid>
                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <TextField
                                        label="Password"
                                        type="password"
                                        variant="filled"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={password}
                                        onChange={event => setPassword(event.target.value)}
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Confirm password"
                                        type="password"
                                        variant="filled"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={password2}
                                        onChange={event => setPassword2(event.target.value)}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{color: "white"}}
                                    endIcon={<LockResetIcon />}
                                    fullWidth
                                    onClick={resetPassword}
                                    disabled={loading}
                                >
                                    Reset password
                                </Button>
                            </Grid>  
                            { error ? (
                                <Grid item>
                                    <Alert variant="filled" severity="error">{ error }</Alert>
                                </Grid>
                            ) : null }
                        </Grid>
                    </CenteredAuthForm>
                ) : (
                    <CenteredAuthForm>
                        <Grid container direction="column" spacing={5}>
                            <Grid item>
                                <Heading>Reset password</Heading>
                            </Grid>
                            <Grid item>
                                <SubHeading>Success! Please login with your new password.</SubHeading>
                            </Grid>
                        </Grid>
                    </CenteredAuthForm>
                )}

            </AuthSection>

            <AuthSection isMobile={isMobile}>
                <AuthGallery isMobile={isMobile} condensedOverlay>
                    <h3>#MadeWithMakers</h3>
                </AuthGallery>
            </AuthSection>
        </AuthContainer>
    )
}

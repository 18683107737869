import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { NumericFormat } from "react-number-format"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"

import { TextField, Grid, Autocomplete, InputLabel } from "@mui/material"

import { request } from "@/Api"
import Projects from "@/projects/Projects"
import ApiAutocomplete from "@/common/ApiAutocomplete"
import { currencies } from "@/constants"
import { getCurrencySymbol } from "@/utils"
import { selectHasPermission } from "@/auth/authSlice"

export default function Client({}){
    const { clientId } = useParams()
    const [ client, setClient ] = useState(null)
    const canSetForecast = useSelector(state => selectHasPermission(state, "edit_client_forecasts"))

    const handleBlur = (field) => (event) => {
        request.put(`/clients/${clientId}`, {...client, [field]: event.target.value})
        .then((response)=>{})
    }
    const handleInput = (field) => (event) => {
        const isNumber = event.target.type
        setClient({...client, [field]: event.target.value})
    }
    const handleAutocompleteChange = (field) => (event, newValue) => {
        request.put(`/clients/${clientId}`, {...client, [field]: newValue})
        .then((response)=>{
            setClient(response.data)
        })
    }
    const getClient = () => {
        request.get(`/clients/${clientId}`)
        .then((response)=>{
            setClient(response.data)
        })
    }
    useEffect(()=>{
        getClient()
    }, [])

    if (!client) {
        return null
    }

    return (
        <>
            <Helmet><title>{client.name} - Makers Central</title></Helmet>

            <TextField
                sx={{paddingBottom: 3}}
                value={client.name || ""}
                onChange={handleInput("name")}
                onBlur={handleBlur("name")}
                placeholder="Client Name"
                variant="standard"
                inputProps={{style: {fontSize: "36px"}}}
                InputLabelProps={{style: {fontSize: "36px"}}}
                fullWidth
            />
            <h3>Details</h3>
            <Grid container spacing={3} paddingBottom={3}>
                <Grid item md={3}>
                    <InputLabel>Currency</InputLabel>
                    <Autocomplete 
                        fullWidth
                        value={client.currency}
                        options={currencies}
                        onChange={handleAutocompleteChange("currency")}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Grid>
                <Grid item md={3}>
                    <InputLabel>Entity</InputLabel>
                    <ApiAutocomplete
                        fullWidth
                        value={client.legal_entity_id || null}
                        apiRoute={"legal-entities"}
                        fields={["name"]}
                        onChange={handleAutocompleteChange("legal_entity_id")}
                    />
                </Grid>
                <Grid item md={3}>
                    <InputLabel>Owner</InputLabel>
                    <ApiAutocomplete
                        fullWidth
                        value={client.owner_id || null}
                        apiRoute={"persons"}
                        fields={["first_name", "last_name"]}
                        onChange={handleAutocompleteChange("owner_id")}
                        apiFilters={{
                            role: "executive_producer"
                        }}
                    />
                </Grid>
                <Grid item md={3}>
                    <InputLabel>Type</InputLabel>
                    <ApiAutocomplete
                        fullWidth
                        value={client.client_type_id || null}
                        apiRoute={"client-types"}
                        fields={["name"]}
                        onChange={handleAutocompleteChange("client_type_id")}
                    />
                </Grid>
            </Grid>

            <h3>Client Forecast</h3>

            <Grid container spacing={3} marginBottom={6}>
                {client.forecast_quarters.map(forecast=>
                    <Grid key={forecast.id} item md={12 / client.forecast_quarters.length}>
                        <InputLabel>{forecast.quarter_string}</InputLabel>
                        <ClientForecast forecast={forecast} currency={client.currency} disabled={!canSetForecast}/>
                    </Grid>
                )}
            </Grid>

            <h3>Projects</h3>

            <Grid paddingBottom={10}>
                <Projects prefixedFilters={{
                    client_id: `eq:${clientId}`
                }}/>
            </Grid>
        </>
    )
}

const ClientForecast = ({forecast, currency, disabled}) => {
    const [ focused, setFocused ] = useState(false)
    const [ forecastLocal, setForecastLocal ] = useState(forecast)
    const handleValueChangeAmount = (value, event) => {
        setForecastLocal({...forecastLocal, "amount": value.value})
    }
    const handleBlurAmount = () => {
        request.put(`/client-forecasts/${forecast.id}`, forecastLocal)
        .then(()=>{
            setFocused(false)
        })
    }
    return (
        <NumericFormat
            disabled={disabled}
            value={forecastLocal.amount}
            valueIsNumericString={true}
            fullWidth
            customInput={TextField}
            onValueChange={handleValueChangeAmount}
            onBlur={handleBlurAmount}
            thousandSeparator=","
            prefix={getCurrencySymbol(currency)}
            onFocus={(e)=>{e.target.select()}}
        />
    )
}
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet"

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { Editor, EditorState, convertFromRaw } from "draft-js";

import { request } from '@/Api';

export default function PublicTerm({name}){
    const theme = useTheme()
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const isSmall = useMediaQuery(theme.breakpoints.down("md"))
    const deserializeContentState = (contentStateString) => {
        const contentStateObject = JSON.parse(contentStateString)
        const contentState = convertFromRaw(contentStateObject)
        const editorState = EditorState.createWithContent(contentState)
        return editorState
    }
    
    const getTerm = () => {
        request.get(`public-terms/${name}`)
        .then(response=>{
            setEditorState(deserializeContentState(response.data.draft_js_raw_state_string))
        })
    }

    useEffect(()=>{
        getTerm()
    }, [])

    return (
        <div style={{lineHeight: 2, paddingLeft: isSmall? 0 : 150, paddingRight: isSmall? 0 : 150}}>
            <Helmet><title>{name} - Makers Central</title></Helmet>

            <h2>{name}</h2>
            <Editor
                editorState={editorState}
                readOnly
            />
        </div>
    )
}
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Helmet } from "react-helmet"

import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Snackbar from '@mui/material/Snackbar'
import TextField from "@mui/material/TextField"

import LoginIcon from "@mui/icons-material/Login"
import VerifiedIcon from "@mui/icons-material/Verified"

import { requestSilent } from "@/Api"
import AuthGallery from "@/auth/AuthGallery"
import { AuthContainer, AuthSection, CenteredAuthForm, Heading, SubHeading, GoogleLoginButton, OrLine, Footer, AuthFormCentered } from "@/auth/StyledComponents"
import { GOOGLE_CLIENT_ID } from "@/config"
import { GlobalStyle } from "@/themes"
import { setToken, selectIsProfileComplete, selectIsLoggedIn } from "@/auth/authSlice"
import { InternalLink } from "@/common/CommonComponents"

import "$/makers-interactive-logo/index"
import useInvitation from "./authHooks"

export default function SignUp() {
    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { inviteToken } = useParams()

    const [ loading, setLoading ] = useState(false)
    const [ step, setStep ] = useState(1)
    const [ email, setEmail ] = useState("")
    const [ firstName, setFirstName ] = useState("")
    const [ lastName, setLastName ] = useState("")
    const [ password, setPassword ] = useState("")
    const [ password2, setPassword2 ] = useState("")
    const [ agree, setAgree ] = useState(false)
    const [ errors, setErrors ] = useState([])
    const [ invitationLoaded, setInvitationLoaded ] = useState(false)

    const themeMode = localStorage.getItem("themeMode") || "light"
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const isProfileComplete = useSelector(selectIsProfileComplete)
    const invitation = useInvitation()

    useEffect(()=>{
        if (invitation && !invitationLoaded){
            invitation.tryGoToLogin()
            setEmail(invitation.invitee.email)
            setFirstName(invitation.invitee.first_name)
            setLastName(invitation.invitee.last_name)
            setInvitationLoaded(true)
        }
    }, [invitation])

    useEffect(() => {
        if (isLoggedIn) {
            if (isProfileComplete) {
                navigate("/profile")
            } else {
                navigate("/set-up")
            }
        }
    }, [isLoggedIn, isProfileComplete])

    const signUp = (data) => {
        setErrors([])
        setLoading(true)

        requestSilent.post("sign-up", {...data, token: inviteToken}).then(response => {            
            dispatch(setToken(response.data))
        }).catch(error => {
            const detail = error?.response?.data?.detail || "There was an error logging in, please contact an admin"
            if (Array.isArray(detail)) {
                setErrors(detail.map(e => e.msg))
            } else {
                setErrors([detail])
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const googleButton = useRef(null)
    useEffect(()=>{
        const script = document.createElement('script')
        script.src = "https://accounts.google.com/gsi/client"
        script.async = true

        script.onload = ()=>{
            google.accounts.id.initialize({
                client_id: GOOGLE_CLIENT_ID,
                callback: (response) => {
                    signUp({source: "google", google_jwt: response.credential})
                },
            })

            const dimensions = googleButton.current.getBoundingClientRect()
            google.accounts.id.renderButton(googleButton.current, {
                theme: "outline",
                size: "large",
                text: "signup_with",
                logo_alignment: "center",
                width: Math.min(dimensions.width, 400),
            })
        }
        document.body.appendChild(script)
        return () => {
          document.body.removeChild(script)
        }
    }, [])

    const submitStep1 = (event) => {
        event.preventDefault()

        setErrors([])
        setLoading(true)

        requestSilent.post("email-availability-check", email)
        .then(() => setStep(2))
        .catch(error => {
            const detail = error?.response?.data?.detail || "There was an error, please contact an admin"
            if (Array.isArray(detail)) {
                setErrors(detail.map(e => e.msg))
            } else {
                setErrors([detail])
            }
        })
        .finally(() => {
            setLoading(false)
        })

    }

    const submitStep2 = (event) => {
        event.preventDefault()

        const newErrors = []
        if (password != password2) {
            newErrors.push("Passwords do not match.")
        }
        if (!agree) {
            newErrors.push("Missing consent.")
        }
        if (newErrors.length > 0) {
            setErrors(newErrors)
            return
        }

        signUp({source: "email", email: email, password: password, first_name: firstName, last_name: lastName})
    }

    const handleCloseError = () => {
        setErrors([])
    }

    return (
        <AuthContainer isMobile={isMobile}>
            <Helmet><title>Sign Up - Makers Central</title></Helmet>
            <GlobalStyle theme={themeMode} />            

            <AuthSection isMobile={isMobile}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <makers-logo style={{display: "block", width: "150px"}} />
                    </Grid>
                    {invitation ? null : (
                        <Grid item>
                            <InternalLink href={`/login`}>Have an account?</InternalLink>
                        </Grid>
                    )}
                </Grid>
                
                { step == 1 ? (
                    <CenteredAuthForm onSubmit={submitStep1}>
                        <Grid container direction="column" spacing={5}>
                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <Heading>Sign up</Heading>
                                </Grid>
                                <Grid item>
                                    <SubHeading>{
                                        invitation?.inviter ? `${invitation.inviter.name} has invited you to join their workspace` : 
                                        "Choose your method to get started"
                                    }</SubHeading>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <GoogleLoginButton ref={googleButton} />
                            </Grid>
                            <Grid item container alignItems="center" spacing={1}>
                                <Grid item flexGrow={1}>
                                    <OrLine backgroundColor={theme.palette.text.primary} />
                                </Grid>
                                <Grid item>
                                    OR
                                </Grid>
                                <Grid item flexGrow={1}>
                                    <OrLine backgroundColor={theme.palette.text.primary} />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <TextField
                                    disabled={invitation?.invitee.email}
                                    label="Email"
                                    type="email"
                                    variant="filled"
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{
                                        required: false,
                                    }}
                                    required
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{color: "white"}}
                                    endIcon={<LoginIcon />}
                                    fullWidth
                                >
                                    Sign up
                                </Button>
                            </Grid>
                        </Grid>
                    </CenteredAuthForm>
                ) : null}

                {step == 2 ? (
                    <CenteredAuthForm onSubmit={submitStep2}>
                        <Grid container direction="column" spacing={5}>
                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <Heading>Create your account</Heading>
                                </Grid>
                                <Grid item>
                                    <SubHeading>Set a password to get started</SubHeading>
                                </Grid>
                            </Grid>

                            <Grid item container direction="column" spacing={2}>
                                <Grid item>
                                    <TextField
                                        label="First name"
                                        variant="filled"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={firstName}
                                        onChange={event => setFirstName(event.target.value)}
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Last name"
                                        variant="filled"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={lastName}
                                        onChange={event => setLastName(event.target.value)}
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Password"
                                        type="password"
                                        variant="filled"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={password}
                                        onChange={event => setPassword(event.target.value)}
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Confirm password"
                                        type="password"
                                        variant="filled"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={password2}
                                        onChange={event => setPassword2(event.target.value)}
                                        required
                                    />
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        value={agree}
                                        onClick={() => setAgree(!agree)}
                                    >
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio />}
                                            label="By signing up, you agree to our Privacy Policy and Terms of Service."
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
    
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    sx={{color: "white"}}
                                    fullWidth
                                    disabled={loading}
                                    endIcon={<LoginIcon />}
                                >
                                    Sign up
                                </Button>
                            </Grid>
                        </Grid>

                        <Footer>
                            <InternalLink href={`/sign-up`}>Back</InternalLink>
                        </Footer>
                    </CenteredAuthForm>
                ) : null}

            </AuthSection>

            <AuthGallery isMobile={isMobile}>
                <h3>Join the platform that’s revolutionizing production</h3>
                <List dense>
                    {  
                        [
                            "Access to new project opportunities and clients",
                            "Connect with a global community of producers",
                            "Manage productions with world class tooling",
                            "Level up your skills through training & mentorship",
                        ].map((text, i) => <AuthListItem key={i} text={text} />)
                    }
                </List>
            </AuthGallery>

            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center" }}
                open={errors.length > 0}
                onClose={handleCloseError}
            >
                <Alert
                    variant="filled"
                    severity="error"
                    sx={{ width: '100%' }}
                    onClose={handleCloseError}
                >
                    { errors.join(", ") }
                </Alert>
            </Snackbar>
        </AuthContainer>
    )
}

function AuthListItem({ text }) {
    return (
        <ListItem disableGutters>
            <ListItemIcon sx={{minWidth: "36px"}}>
                <VerifiedIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={text} />
        </ListItem>
    )
}

import { useState } from "react"
import { parse, format } from "date-fns"

import Chip from "@mui/material/Chip"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import { formattedCurrency } from "@/utils"
import { request } from "@/Api"
import { InternalLink } from "@/common/CommonComponents"

export default function ProjectBills({currency, bills, disabled, setProjectBills, showSaveAlert}) {
    const [ cachedValue, setCachedValue ] = useState(null)
    const [ errors, setErrors ] = useState({})

    const saveBill = (billId, field, value) => {
        if (cachedValue == value) {
            setCachedValue(null)
            return
        }

        request.patch(`bills/${billId}`, {[field]: value}).then(() => {
            showSaveAlert()
        }).catch(error => {
            const detail = error.response.data.detail[0]
            setErrors({
                ...errors,
                [billId]: {
                    ...errors[billId],
                    [detail.loc[1]]: detail.msg,
                }
            })
        })
    }

    const clearError = (billId, field) => {
        if (errors[billId] && errors[billId][field]) {
            const { [field]: _removed1, ...remainingErrors } = errors[billId]
            if (Object.keys(remainingErrors).length) {
                setErrors({
                    ...errors,
                    [billId]: remainingErrors,
                })
            } else {
                const { [billId]: _removed2, ...remainingErrors} = errors
                setErrors({
                    ...remainingErrors,
                })
            }
        }
    }

    const handleActualChange = (billId) => (values) => {
        const value = values.value
        setProjectBills(bills.map((bill) => (bill.id == billId) ? {
            ...bill,
            actual_amount: value,
        } : bill))

        clearError(billId, "actual_amount")
    }

    const handleCalendarChange = (billId, save) => (newValue) => {
        let date = ''
        try {
            date = format(newValue, "y-MM-dd")
        } catch (e) {
            // Keep empty string if invalid date
        }

        setProjectBills(bills.map((bill) => (bill.id == billId) ? {
            ...bill,
            bill_date: date
        } : bill))

        clearError(billId, "bill_date")
        
        if (save) {
            saveBill(billId, "bill_date", date)
        }
    }

    const handleNotesChange = (billId) => (event) => {
        const value = event.target.value
        setProjectBills(bills.map((bill) => (bill.id == billId) ? {
            ...bill,
            notes: value
        } : bill))

        clearError(billId, "notes")
    }

    return bills.length == 0 ? (
        <div>Awaiting approved bid sheet...</div>
    ) : (
        <TableContainer sx={{ marginTop: 1, paddingBottom: "6px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow sx={{ "td, th": { border: 0 }}}>
                        <TableCell>ID</TableCell>
                        <TableCell>Source</TableCell>
                        <TableCell>Quoted Percentage</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Bill Date</TableCell>
                        <TableCell>Bill Notes</TableCell>
                        <TableCell>Sent</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                                
                    {bills.map((bill, index) => (
                        <TableRow key={index} sx={index < bills.length-1 && bills[index+1].bid_id != bill.bid_id ? null : { "td, th": { border: 0 }}}>
                            <TableCell width={1} align="center">{bill.id}</TableCell>
                            <TableCell width={1} align="center">
                                <InternalLink href={`/bids/${bill.bid_id}`}>
                                    <Chip label={bill.bill_type} clickable />
                                </InternalLink>
                            </TableCell> 
                            <TableCell width={100} align="left">
                                {parseInt(bill.quoted_percentage * 100)+"%"}
                            </TableCell>
                            <TableCell width={100} align="left">
                                {formattedCurrency(bill.actual_amount||0, currency)}
                            </TableCell>
                            <TableCell width={180}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={bill.bill_date ? parse(bill.bill_date, "y-MM-dd", new Date()) : null}
                                        inputFormat={"yyyy-MM-dd"}
                                        onChange={handleCalendarChange(bill.id, false)}
                                        onAccept={handleCalendarChange(bill.id, true)}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                error={errors[bill.id] && "bill_date" in errors[bill.id]}
                                                helperText={errors[bill.id] ? errors[bill.id]["bill_date"] : ""}
                                                onFocus={() => setCachedValue(bill.bill_date)}
                                                onBlur={() => saveBill(bill.id, "bill_date", bill.bill_date)}
                                                sx={{maxHeight: "56px"}}
                                            />
                                        )}
                                        disabled={ disabled || bill.sent }
                                        disablePast
                                    />
                                </LocalizationProvider>
                            </TableCell>
                            <TableCell width={600} align="left">
                                <TextField 
                                    placeholder="client email / terms / legal name / billing address"
                                    fullWidth 
                                    value={bill.notes || ""} 
                                    onChange={handleNotesChange(bill.id)}
                                    onFocus={() => setCachedValue(bill.notes)}
                                    onBlur={() => saveBill(bill.id, "notes", bill.notes)}
                                    disabled={disabled}
                                />
                            </TableCell>
                            <TableCell align="left">
                                {bill.sent ? <CheckCircleOutlineRoundedIcon color="success"/> : <HighlightOffRoundedIcon color="error"/>}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>   
    )
}

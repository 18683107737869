import { useEffect, useState, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"

import { Avatar, Button, Grid, IconButton, Tooltip, useTheme } from "@mui/material"

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverRounded from "@mui/icons-material/DeleteForeverRounded";
import BusinessIcon from '@mui/icons-material/Business';

import { request } from "@/Api"
import { OrgProfilePopup } from "@/org_profile/OrgProfilePopup";
import { ProjectProfilePopup } from "@/project_profiles/ProjectProfilePopup";
import { ProfileListItem, ProfileListItemLikeButton } from "@/profiles_common/CommonComponents";
import ProjectProfileThumbnail from "@/project_profiles/ProjectProfileThumbnail";
import { parseVideoUrlToEmbedUrl } from "@/utils";
import ProfileAvatar from "@/common/ProfileAvatar";
import { ProfileImagePopup } from "@/profiles_common/ProfileImagePopup";
import { ProfileStyle } from "@/profiles_common/profileStyle";
import { DottedDiv } from "@/common/StyledComponents";
import ProtectedComponents from "@/auth/ProtectedComponents";
import PersonProfileAvatar from "@/person_profiles/PersonProfileAvatar";
import { Heading } from "@/common/StyledComponents";
import { CopyLinkButton, CustomIcon } from "@/common/CommonComponents";
import { selectHasPermission } from "@/auth/authSlice"

export function OrgProfile(){
    const theme = useTheme()
    const navigate = useNavigate()

    const { profileHandle } = useParams()
    const [ orgProfile, setOrgProfile ] = useState(null)
    const [ orgPopupOpen, setOrgPopupOpen ] = useState(false)
    const [ projectPopupOpen, setProjectPopupOpen ] = useState(false)
    const [ profileImagePopupOpen, setProfileImagePopupOpen ] = useState(false)
    const [ profilePicUrl, setProfilePicUrl ] = useState(null)
    const [ bannerPicUrl, setBannerPicUrl ] = useState(null)

    const fileInputRef = useRef()

    const isAdmin = useSelector(state => selectHasPermission(state, "view_admin_panel"))
    const isMyOrg = orgProfile ? orgProfile.belong : false
    const canEdit = isMyOrg && isAdmin

    useEffect(()=>{
        request.get(`org-profiles/${profileHandle}`)
        .then(response=>{
            if (!response.data){
                navigate("/login")
            }
            setOrgProfile(MapOrgProfile(response.data))
            setProfilePicUrl(response.data.image ? response.data.image.url_thumb : null)
            setBannerPicUrl(response.data.banner_image ? response.data.banner_image.url_orig : null)
        })
    }, [])

    const handleBannerFileSelect = () => {
        const file = fileInputRef.current.files[0]
        const formData = new FormData()
        formData.append("file", file, file.name)
        formData.append("org_profile_id", orgProfile.id)
        request.post(`org-profiles/${orgProfile.id}/banner_image`, formData)
        .then(response=>{
            setBannerPicUrl(response.data.banner_image ? response.data.banner_image.url_orig : null)
        })
    }

    const handleBannerFileDelete = () => {
        request.delete(`org-profiles/${orgProfile.id}/banner_image`)
        .then(()=>{
            setBannerPicUrl(null)
        })
    }
    
    if (!orgProfile){
        return null
    }

    return (
        <ProfileStyle>
            <Helmet><title>{orgProfile.name} - Makers Central</title></Helmet>

            <div style={{height: 195, marginTop: 40, position: "relative"}}>
                <DottedDiv backgroundColor={theme.palette.background.rainbow} dotColor={"#ffffff11"}>
                    <div style={{height: 250, objectFit: "cover", flex: "none"}}>
                        { bannerPicUrl && <img src={bannerPicUrl} style={{objectFit: "cover", height: "100%", width: "100%", borderRadius: "10px",}}/> }
                    </div>
                </DottedDiv>
                <div hidden={!canEdit}>
                    <ProtectedComponents>
                        <div style={{position: "absolute", top: 5, right: 5, background: theme.palette.background.paper, borderRadius: "50%"}}>
                            <IconButton size="small" onClick={()=>{fileInputRef.current.click()}}>
                                <CreateOutlinedIcon fontSize="tiny" />
                            </IconButton>
                            <input hidden type="file" ref={fileInputRef} onChange={handleBannerFileSelect} accept="image/png, image/jpeg, image/webp"/>
                        </div>
                        <div hidden={!bannerPicUrl} style={{position: "absolute", top: 5, right: 40, background: theme.palette.background.paper, borderRadius: "50%"}}>
                            <IconButton size="small" onClick={handleBannerFileDelete}>
                                <DeleteForeverRounded fontSize="tiny" color="error"/>
                            </IconButton>
                        </div>
                    </ProtectedComponents>
                </div>
            </div>
            <div style={{position: "relative", width: 120, height: 120, marginBottom: 20, marginLeft: 20}}>
                <Avatar sx={{width: "100%", height: "100%"}} src={profilePicUrl}>
                    <BusinessIcon fontSize="large" sx={{width: "75%", height: "75%"}}/>
                </Avatar>
                <div hidden={!canEdit}>
                    <ProtectedComponents>
                        <IconButton onClick={()=>{setProfileImagePopupOpen(true)}} size="small" style={{
                            position: "absolute", width: 25, height: 25,
                            background: theme.palette.background.default,
                            border: `1px solid ${theme.palette.background.paper}`,
                            bottom: 3, left: 3, borderRadius: "50%"
                        }}>
                            <CreateOutlinedIcon fontSize="tiny"/>
                        </IconButton>
                    </ProtectedComponents>
                </div>
                <IconButton size="small" style={{
                    cursor: "default",
                    position: "absolute", width: 30, height: 30, 
                    background: theme.palette.background.default,
                    border: `1px solid ${theme.palette.background.paper}`,
                    bottom: 1, right: 1, borderRadius: "50%"
                }}>
                    {profilePicUrl ? <CustomIcon size={23} imgUrl={profilePicUrl} /> : <VerifiedIcon fontSize="tiny"/>}
                </IconButton>
            </div>
            <Grid container spacing={8}>
                <Grid item md={8.5}>
                    <Grid container alignItems={"center"} spacing={1} flexDirection={"row"} paddingBottom={1}>
                        <Grid item>
                            <Heading>
                                {orgProfile.name}
                            </Heading>
                        </Grid>
                    </Grid>
                    <Grid container alignItems={"center"} spacing={1} flexDirection={"row"} paddingBottom={8} justifyContent={"space-between"}>
                        <Grid item md={12} xs={12}>
                            <div>
                                <span>{orgProfile.headline}</span>
                            </div>
                        </Grid>
                        <Grid item container alignItems={"center"} spacing={2} width={"fit-content"}>
                            <ProfileListItem icon={<AlternateEmailOutlinedIcon fontSize="16px"/>} text={orgProfile.handle} />
                            {orgProfile.location ?
                                <ProfileListItem icon={<FmdGoodOutlinedIcon fontSize="16px" />} text={orgProfile.location.short_name} /> 
                            : null}
                            {orgProfile.created_at ?
                                <ProfileListItem icon={<CalendarTodayOutlinedIcon fontSize="16px" />} text={`Joined ${orgProfile.created_at}`} />
                            : null}
                            <Grid item>
                                <ProfileListItemLikeButton likableEntity={orgProfile} setLikableEntity={setOrgProfile} modelType={"org_profile"}/>
                            </Grid>
                        </Grid>
                     
                        <Grid item container alignItems={"center"} spacing={1} width={"max-content"}>
                            {
                                isMyOrg && isAdmin ? 
                                    <>
                                        <Grid item>
                                            <Button
                                                variant="contained" color="secondary" size="small"
                                                sx={{boxShadow: "none"}} startIcon={<CreateOutlinedIcon />}
                                                onClick={()=>{setOrgPopupOpen(true)}}
                                            >
                                                <span style={{lineHeight: 1.5, fontSize: 12}}>Edit Profile</span>
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained" color="secondary" size="small"
                                                sx={{boxShadow: "none"}} startIcon={<AddRoundedIcon />}
                                                onClick={()=>{setProjectPopupOpen(true)}}
                                            >
                                                <span style={{lineHeight: 1.5, fontSize: 12}}>Add Project</span>
                                            </Button>
                                        </Grid>
                                    </>
                                : null
                            }
                            {/* TODO: implement report profile feature */}
                            {/* <Grid item>
                                <Button
                                    variant="contained" color="secondary" size="small"
                                    sx={{boxShadow: "none", minWidth: 0, width: "28px"}}
                                >
                                    <MoreVertRoundedIcon fontSize="small"/>
                                </Button>
                            </Grid> */}
                            <Grid item>
                                <CopyLinkButton />
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <div style={{paddingBottom: 50}} hidden={!orgProfile.description}>
                        <Heading style={{fontSize: 16, paddingBottom: 20}}>Background</Heading>
                        <span style={{overflowWrap: "anywhere", fontSize: 14, whiteSpace: "pre-line"}}>{orgProfile.description}</span>
                    </div>

                    <div style={{paddingBottom: 50}} hidden={!orgProfile.reel_url}>
                        <Heading style={{fontSize: 16, paddingBottom: 20}}>Reel</Heading>
                        {orgProfile.reel_url ? 
                            <iframe 
                                style={{border: "none", borderRadius: "10px", width: "100%", height: 400}}
                                src={parseVideoUrlToEmbedUrl(orgProfile.reel_url)}
                            />
                        : null}
                    </div>
                        
                    <div hidden={orgProfile.project_profiles.length == 0}>
                        <Heading style={{fontSize: 16, paddingBottom: 20}}>Projects</Heading>
                        {orgProfile.project_profiles.map((project, index)=>{
                            return (
                                <ProjectProfileThumbnail key={index} project={project}/>
                            )
                        })}
                    </div>
                    
                </Grid>
                <Grid item md={3.5}>
                    <div style={{paddingBottom: 50}} hidden={orgProfile.client_profiles.length == 0}>
                        <Heading style={{fontSize: 16, paddingBottom: 20}}>Clients</Heading>
                        <Grid container spacing={2}>
                            {orgProfile.client_profiles.map((client_profile, index)=>
                                <Grid key={index} item>
                                     <Tooltip title={client_profile.name}>
                                        <div>
                                            <ProfileAvatar image={client_profile.image} name={client_profile.name} size={35} darkModeInvert />
                                        </div>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                    <div style={{paddingBottom: 50}} hidden={orgProfile.person_profiles.length == 0}>
                        <Heading style={{fontSize: 16, paddingBottom: 20}}>Team</Heading>
                        <Grid container spacing={2}>
                            {orgProfile.person_profiles.map((person_profile, index)=>
                                <Grid key={index} item>
                                     <Tooltip title={person_profile.name}>
                                        <div>
                                            <PersonProfileAvatar person_profile={person_profile} size={35} />
                                        </div>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Grid>

            </Grid>

            <OrgProfilePopup 
                orgProfile={orgProfile} 
                setOrgProfile={setOrgProfile}
                orgPopupOpen={orgPopupOpen}
                closePopup={()=>{setOrgPopupOpen(false)}}
            />

            <ProjectProfilePopup
                orgProfileId={orgProfile.id}
                projectProfile={null}
                projectPopupOpen={projectPopupOpen}
                closePopup={()=>{setProjectPopupOpen(false)}}
                addProjectProfile={(projectProfile)=>{
                    setOrgProfile({...orgProfile, project_profiles: [projectProfile, ...orgProfile.project_profiles]})
                }}
            />

            <ProfileImagePopup
                profileType={"org-profile"}
                profileId={orgProfile.id}
                profileImagePopupOpen={profileImagePopupOpen}
                profilePicUrl={profilePicUrl}
                setProfilePicUrl={setProfilePicUrl}
                closePopup={()=>{setProfileImagePopupOpen(false)}}
            />
        </ProfileStyle>
    )
}


export const MapOrgProfile = (orgProfile) => {
    return ({
        ...orgProfile, 
        new_client_profiles: [], 
        client_profile_ids: orgProfile.client_profiles.map(orgProfile=>orgProfile.id),
    })
}

import { useState } from "react"
import { Helmet } from "react-helmet"

import TableCell from "@mui/material/TableCell"

import { Table, TableHead, TableRow, TableBody } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2'

import CustomTableContainer from "@/table//CustomTableContainer"
import BillRow from "@/bills/BillRow"
import BillFilters from "@/bills/BillFilters"
import { formattedCurrency } from "@/utils"

export default function Bills() {
    const [totals, setTotals] = useState({
        "actual_amount": "-", 
        "confident_amount": "-",
        "stretch_amount": "_"
    })

    return(
        <>
            <Helmet><title>Bills - Makers Central</title></Helmet>
            
            <CustomTableContainer
                modelType="bill"
                defaultOrder="-bill_date"
                title="Bills"
                headCells={[
                    {id: "_", label: ""},
                    {id: "bill_date", label: "Bill Date", orderable: true},
                    {id: "docket", label: "Docket"},
                    {id: "bill_type", label: "Type"},
                    {id: "certainty", label: "Certainty"},
                    {id: "quoted_percentage", label: "Percentage"},
                    {id: "estimated_amount", label: "Estimated Rev", numeric: true},
                    {id: "actual_amount", label: "Actual Rev", orderable: true, numeric: true},
                    {id: "project_name", label: "Project Name"},
                    {id: "project_status", label: "Project Status"},
                    {id: "client_name", label: "Client Name"},
                    {id: "notes", label: "Notes"},
                    {id: "signed", label: "Signed"},
                    {id: "sent", label: "Sent"},
                    {id: "_delete", label: "Delete"},
                ]}
                itemRowComponent={BillRow}
                filterComponents={BillFilters}
                setAdditionalData={(data) => {
                    setTotals({
                        "actual_amount": formattedCurrency(data.total_actual_amount,"CAD"),
                        "confident_amount": formattedCurrency(data.total_confident_amount,"CAD"),
                        "stretch_amount": formattedCurrency(data.total_stretch_amount,"CAD")
                    })
                }}
            >
                <Grid container sx={{marginBottom: "24px"}}>
                    <Grid item xs={12} md={6}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Stretch Total</TableCell>
                                    <TableCell align="left">Confident Total</TableCell>
                                    <TableCell align="left">Actual Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">{totals.stretch_amount}</TableCell>
                                    <TableCell align="left">{totals.confident_amount}</TableCell>
                                    <TableCell align="left">{totals.actual_amount}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </CustomTableContainer>
        </>
    )
}

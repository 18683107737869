import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"

import { request } from "@/Api"
import CustomTableContainer from "@/table//CustomTableContainer"
import ProjectRow from "@/projects/ProjectRow"
import ProjectFilters from "@/projects/ProjectFilters"
import { ACCOUNTING_STRATEGY, RESOURCING_STRATEGY } from "@/constants"
import { selectHasPermission, selectHasStrategy } from "@/auth/authSlice"

const headCells = {
    docket: {id: "docket", label: "Docket", orderable: true, sticky: true}, 
    docket_restricted: {id: "docket", label: "Docket", orderable: true, sticky: true}, 
    name: {id: "name", label: "Name", orderable: true}, 
    client_id: {id: "client_id", label: "Client", orderable: true}, 
    legal_entity_id: {id: "legal_entity_id", label: "Legal Entity", orderable: true}, 
    status_id: {id: "status_id", label: "Status", orderable: true}, 
    start_date: {id: "start_date", label: "Start Date", orderable: true}, 
    end_date: {id: "end_date", label: "End Date", orderable: true},
    live_date: {id: "live_date", label: "Live Date", orderable: true},
    currency: {id: "currency", label: "Currency"},
    signed: {id: "signed", label: "Signed"},
    forecast_revenue: {id: "forecast_revenue", label: "Rev. Forecast", orderable: true, numeric: true},
    confidence: {id: "confidence", label: "Confidence"},
    float_internal_actual: {id: "float_internal_actual", label: "Internal Actual", orderable: true, numeric: true},
    bid_internal_budget: {id: "bid_internal_budget", label: "Internal Budget", orderable: true, orderBy: "bid_internal_budget_base", numeric: true},
    bid_quoted: {id: "bid_quoted", label: "Quoted Total", orderable: true, orderBy: "bid_quoted_base", numeric: true},
    bid_hard_cost: {id: "bid_hard_cost", label: "Hard Cost Total", orderable: true, orderBy: "bid_hard_cost_base", numeric: true},
    bid_gp: {id: "bid_gp", label: "GP Total", orderable: true, orderBy: "bid_gp_base", numeric: true},
    accounting_rev: {id: "accounting_rev", label: "Billed", orderable: true, numeric: true},
    accounting_cost: {id: "accounting_cost", label: "Spent", orderable: true, numeric: true},
    percentage_complete: {id: "percentage_complete", label: "% Complete"},
    percentage_billed: {id: "percentage_billed", label: "% Billed"},
    earned_rev: {id: "earned_rev", label: "Earned Rev", numeric: true},
    eps: {id: "eps", label: "Executive Producers"},
    persons: {id: "persons", label: "Team Members"},
    _actions: {id: "_actions", label: ""},
    first_forecast_date: {id: "first_forecast_date", label: "First Forecast Date"},
    last_forecast_date: {id: "last_forecast_date", label: "Last Forecast Date"},
    first_bill_date: {id: "first_bill_date", label: "First Bill Date"},
    last_bill_date: {id: "last_bill_date", label: "Last Bill Date"},
    project_type_id: {id: "project_type_id", label: "Project Type"}, 
    client_type: {id: "client_type", label: "Client Type"},
}

export default function Projects({prefixedFilters, prefixedTableViewFields, root}) {
    const hasResourcingStrategy = useSelector((state) => selectHasStrategy(state, RESOURCING_STRATEGY))
    const hasAccountingStrategy = useSelector((state) => selectHasStrategy(state, ACCOUNTING_STRATEGY))
    const confidentialDataPermission = useSelector(state => selectHasPermission(state, "view_confidential_data"))

    const [tableFields, setTableFields] = useState([])
    const [tableHeadCells, setTableHeadCells] = useState([])

    const getTableView = () => {
        request.get("/table_view/project")
        .then(function (response) {
            let fields = response.data.fields.split(",")
            if (!confidentialDataPermission) {
                fields = fields.map(field => field == "docket" ? "docket_restricted" : field)
            }

            // TODO: consider less hardcoded ways:
                // create "table_field" db table, and have strategy_id per field
                // create backend service for filtering by strategy
                // create table_view entry by strategies, and maintain the table_view by switch in strategy
            fields = fields.filter(field=>{
                if (field == "accounting_rev" && !hasAccountingStrategy) return false
                if (field == "accounting_cost" && !hasAccountingStrategy) return false
                if (field == "float_internal_actual" && !hasResourcingStrategy) return false
                return true
            })

            setTableFields(fields)
            setTableHeadCells([
                ...fields.map(field => headCells[field]),
            ])
        })
    }

    useEffect(()=>{
        if (prefixedTableViewFields){
            setTableFields(prefixedTableViewFields)
            setTableHeadCells([
                ...prefixedTableViewFields.map(field => headCells[field]),
            ])
            return
        }
        getTableView()
    }, [])

    return (
        <CustomTableContainer
            prefixedFilters={prefixedFilters}
            modelType="project"
            defaultOrder="-docket"
            title="Projects"
            headCells={tableHeadCells}
            itemRowComponent={ProjectRow}
            itemRowProps={{
                tableFields: tableFields,
            }}
            filterComponents={ProjectFilters}
        >
            {root && <Helmet><title>Projects - Makers Central</title></Helmet>}
        </CustomTableContainer>
    )
}

import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Helmet } from "react-helmet"

import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Snackbar from '@mui/material/Snackbar'
import TextField from "@mui/material/TextField"

import LoginIcon from "@mui/icons-material/Login"
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import { requestSilent } from "@/Api"
import AuthGallery from "@/auth/AuthGallery"
import { AuthContainer, AuthSection, CenteredAuthForm, Heading, SubHeading, GoogleLoginButton, OrLine } from "@/auth/StyledComponents"
import { GOOGLE_CLIENT_ID } from "@/config"
import { GlobalStyle } from "@/themes"
import { setToken, selectIsLoggedIn, selectHasPermission } from "@/auth/authSlice"
import { InternalLink } from "@/common/CommonComponents"

import "$/makers-interactive-logo/index"
import useInvitation from "./authHooks"
import OTPInput from "./OTPInput"

export default function Login() {
    const theme = useTheme()
    const { inviteToken } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [ loading, setLoading ] = useState(false)
    const [ email, setEmail ] = useState("")
    const [ password, setPassword ] = useState("")
    const [ otp, setOtp ] = useState("")
    const [ otpRequired, setOtpRequired ] = useState(false)
    const [ error, setError ] = useState("")
    const [ warning, setWarning ] = useState("")
    const themeMode = localStorage.getItem("themeMode") || "light"
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const hasProjectPermission = useSelector(state => selectHasPermission(state, "get_projects"))
    const invitation = useInvitation()

    useEffect(()=>{
        if (invitation){
            invitation.tryGoToSignUp()
            setEmail(invitation.invitee.email)
        }
    }, [invitation])

    useEffect(() => {
        if(isLoggedIn) {
            const savedPath = localStorage.getItem("savedPath")
            if (savedPath) {
                navigate(savedPath)
                localStorage.removeItem("savedPath")
            } else {
                if (hasProjectPermission) {
                    navigate("/dashboard")
                } else {
                    navigate("/profile")
                }
            }
        }
    }, [isLoggedIn])

    const signIn = (data) => {
        setError("")
        setLoading(true)

        requestSilent.post("login", {...data, token: inviteToken}, {withCredentials: true}).then(response => {  
            if (response.status == 201){
                setOtpRequired(true)
                setWarning(response.data)
            }
            else if (response.status == 202){
                setOtp("")
                setWarning(response.data)
            }
            else{
                dispatch(setToken(response.data))
            }
        }).catch(error => {
            const detail = error?.response?.data?.detail
            setError(typeof detail === "string" ? detail : "Invalid username or password.")
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleCloseError = () => {
        setError("")
    }

    const handleCloseWarning = () => {
        setWarning("")
    }

    const submitForm = (event) => {
        event.preventDefault()

        if (!localStorage.getItem("deviceId")){
            localStorage.setItem("deviceId", crypto.randomUUID())
        }
        signIn({email: email, password: password, otp: otp, device_id: localStorage.getItem("deviceId")})
    }

    const handleOtpBackButton = () => {
        setOtpRequired(false)
        setOtp("")
    }

    const googleButton = useRef(null)
    useEffect(()=>{
        const script = document.createElement('script')
        script.src = "https://accounts.google.com/gsi/client"
        script.async = true

        script.onload = ()=>{
            google.accounts.id.initialize({
                client_id: GOOGLE_CLIENT_ID,
                callback: (response) => signIn({google_jwt: response.credential})
            })

            const dimensions = googleButton.current.getBoundingClientRect()
            google.accounts.id.renderButton(googleButton.current, {
                theme: "outline",
                size: "large",
                text: "signin_with",
                logo_alignment: "center",
                width: Math.min(dimensions.width, 400),
            })
        }
        document.body.appendChild(script)
        return () => {
          document.body.removeChild(script)
        }
    }, [])

    return (
        <AuthContainer isMobile={isMobile}>
            <Helmet><title>Login - Makers Central</title></Helmet>
            <GlobalStyle theme={themeMode} />            

            <AuthSection isMobile={isMobile}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <makers-logo style={{display: "block", width: "150px"}} />
                    </Grid>
                    <Grid item>
                        {!invitation && (
                            <InternalLink href={`/sign-up`} color="inherit">Create an account</InternalLink>
                        )}
                    </Grid>
                </Grid>

                <CenteredAuthForm onSubmit={submitForm} hidden={otpRequired}>
                    <Grid container direction="column" spacing={5}>
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <Heading>{`Welcome back${invitation ? `, ${invitation.invitee.name}` : ""}`}</Heading>
                            </Grid>
                            <Grid item>
                                <SubHeading>{
                                    invitation?.inviter ? `${invitation.inviter.name} has invited you to join their workspace` : 
                                    `Enter your account details to continue`}
                                </SubHeading>
                            </Grid>
                        </Grid>
    
                        <Grid item>
                            <GoogleLoginButton ref={googleButton} />
                        </Grid>
                        <Grid item container alignItems="center" spacing={1}>
                            <Grid item flexGrow={1}>
                                <OrLine backgroundColor={theme.palette.text.primary} />
                            </Grid>
                            <Grid item>
                                OR
                            </Grid>
                            <Grid item flexGrow={1}>
                                <OrLine backgroundColor={theme.palette.text.primary} />
                            </Grid>
                        </Grid>
                        
                        <Grid item container direction="column" textAlign="right" spacing={1} hidden>
                            <Grid item>
                                <TextField
                                    disabled={invitation?.invitee.email}
                                    label="Email"
                                    type="email"
                                    variant="filled"
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{
                                        required: false,
                                    }}
                                    required
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Password"
                                    type="password"
                                    variant="filled"
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    InputLabelProps={{
                                        required: false,
                                    }}
                                    required
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <InternalLink href={`/forgot-password`}>Forgot password?</InternalLink>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{color: "white"}}
                                endIcon={<LoginIcon />}
                                fullWidth
                                type="submit"
                                disabled={loading}
                            >
                                Sign in
                            </Button>
                        </Grid>
                    </Grid>
                </CenteredAuthForm>

                <CenteredAuthForm onSubmit={submitForm} hidden={!otpRequired}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <OTPInput
                                otp={otp}
                                setOtp={setOtp}
                            />
                        </Grid>
                        <Grid item width={"100%"}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{color: "white"}}
                                endIcon={<LoginIcon />}
                                fullWidth
                                type="submit"
                                disabled={loading}
                            >
                                Sign in with verification code
                            </Button>
                        </Grid>
                        <Grid item width={"100%"}>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<ArrowBackRoundedIcon />}
                                fullWidth
                                disabled={loading}
                                onClick={handleOtpBackButton}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </CenteredAuthForm>

            </AuthSection>

            <AuthSection isMobile={isMobile}>
                <AuthGallery isMobile={isMobile} condensedOverlay>
                    <h3>#MadeWithMakers</h3>
                </AuthGallery>
            </AuthSection>

            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center" }}
                open={error != ""}
                onClose={handleCloseError}
            >
                <Alert
                    variant="filled"
                    severity="error"
                    sx={{ width: '100%' }}
                    onClose={handleCloseError}
                >
                    { error }
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center" }}
                open={warning != ""}
                onClose={handleCloseWarning}
            >
                <Alert
                    variant="filled"
                    severity="warning"
                    sx={{ width: '100%' }}
                    onClose={handleCloseWarning}
                >
                    { warning }
                </Alert>
            </Snackbar>
        </AuthContainer>
    )
}
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"

import { useTheme, useMediaQuery, Grid, Snackbar, Alert, CircularProgress } from "@mui/material"

import { request } from "@/Api"
import CompanySection from "@/account/CompanySection"
import GeneralSection from "@/account/GeneralSection"
import OrganizationsSection from "@/account/OrganizationsSection"
import ManagementSection from "@/account/ManagementSection"
import PersonalSection from "@/account/PersonalSection"
import SecuritySection from "@/account/SecuritySection"
import { selectHasRole } from "@/auth/authSlice"

export default function Account() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    const [ loading, setLoading ] = useState(true)
    const [ saved, setSaved ] = useState(false)
    const [ error, setError ] = useState("")
    const [ account, setAccount ] = useState({})
    const [ organizations, setOrganizations ] = useState([])
    const [ company, setCompany ] = useState({})

    const isAdmin = useSelector((state) => selectHasRole(state, "admin"))
    const isSuperAdmin = useSelector((state) => selectHasRole(state, "super_admin"))

    useEffect(() => {
        setLoading(true)

        const requests = []

        requests.push(request.get("legal-entities/default").then(response => {
            setCompany({...response.data})
        }))

        requests.push(request.get("organizations").then(response => {
            setOrganizations([...response.data])
        }))

        requests.push(request.get("account").then(response => {
            setAccount({...response.data})
        }))
        
        Promise.all(requests).catch(error => {
            setError("Failed to load account settings.")
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    const handleCloseSave = () => {
        setSaved(false)
    }
    const handleCloseError = () => {
        setError("")
    }

    const containerProps = isMobile ? {
        flexDirection: "column",
        spacing: 2,
    } : {
        flexDirection: "row",
        spacing: 6,
        sx: {marginTop: "36px"},
    }

    return (
        <Grid container wrap="nowrap" {...containerProps}>
            <Helmet><title>Manage Account - Makers Central</title></Helmet>

            <Grid item>
                <h2>My Account</h2>
                <p>Manage your account settings here.</p>
            </Grid>
            { loading ? (
                <Grid item textAlign="center" width="100%">
                    <CircularProgress sx={{marginTop: "48px"}} />
                </Grid>
            ) : (
                <Grid item container direction="column" spacing={3}>
                    <PersonalSection data={account} setData={setAccount} setSaved={setSaved} setError={setError} />
                    <SecuritySection setSaved={setSaved} setError={setError} showGoogleAlert={account.has_google_account} />
                    {/* <GeneralSection setSaved={setSaved} setError={setError} /> */}
                    {(isSuperAdmin || isAdmin) && (
                        <CompanySection data={company} setData={setCompany} setSaved={setSaved} setError={setError} />
                    )}
                    <OrganizationsSection data={organizations} setData={setOrganizations} setSaved={setSaved} setError={setError} />
                    <ManagementSection setError={setError} />
                </Grid>
            )}

            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "left" }}
                open={saved}
                onClose={handleCloseSave}
                autoHideDuration={2000}
            >
                <Alert
                    variant="filled"
                    severity="success"
                    sx={{ width: '100%' }}
                    onClose={handleCloseSave}
                >
                    Account updated
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center" }}
                open={error != ""}
                onClose={handleCloseError}
            >
                <Alert
                    variant="filled"
                    severity="error"
                    sx={{ width: '100%' }}
                    onClose={handleCloseError}
                >
                    { error }
                </Alert>
            </Snackbar>
        </Grid>
    )
}

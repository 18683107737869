import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"

import { Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { Heading } from "../common/StyledComponents"
import { useNavigate, useLocation } from "react-router-dom"

import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'; // project
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'; // invoice
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'; // bid
import Projects from "../projects/Projects";
import { request } from "@/Api";
import { Comment } from "@/common/Comments";
import { DottedSum } from "../common/CommonComponents";
import StrategyComponents from "@/common/StrategyComponents";
import { ACCOUNTING_STRATEGY } from "@/constants";
import { selectPersonId, selectHasPermission } from "@/auth/authSlice"

export default function Dashboard() {
    const navigate = useNavigate()
    const { state } = useLocation() // used to trigger reload when switching organization
    const [person, setPerson] = useState(null)

    const personId = useSelector(selectPersonId)
    const canViewProjects = useSelector(state => selectHasPermission(state, "get_projects"))
    const hasBidApprovalFlow = useSelector(state => selectHasPermission(state, "bid_approval_flow"))

    const getDashboard = () => {
        request.get("dashboard")
        .then((response)=>{
            setPerson(response.data)
        })
    }

    useEffect(()=>{
        if (!canViewProjects) {
            navigate("/profile")
        }
        setPerson(null) // needed to trigger re-render of projects section
        getDashboard()
    }, [state])

    if (!person){
        return
    }

    return (
        <>
            <Helmet><title>Dashboard - Makers Central</title></Helmet>
            <Heading style={{paddingTop: 12}}>{`Welcome, ${person.first_name}.`}</Heading>

            <Grid container spacing={6} paddingBottom={6}>
                <Grid item md={8.5}>
                    <Overview person={person} hasBidApprovalFlow={hasBidApprovalFlow} />
                </Grid>
                <Grid item md={3.5} xs={12}>
                    <DashboardMilestones milestones={person.milestones}/>
                </Grid>
            </Grid>

            <Grid container spacing={6} paddingBottom={6}>
                <Grid item md={person.comments_unresolved.length > 0 ? 8.5 : 12} width={"100%"}>
                    <DashboardProjects personId={`eq:${personId}`}/>
                </Grid>
                {person.comments_unresolved.length > 0 ? 
                <Grid item md={3.5}>
                    <DashboardComments comments={person.comments_unresolved}/>
                </Grid> : null}
            </Grid>
        </>
    );
}

const Overview = ({person, hasBidApprovalFlow}) => {
    return (
        <>
            <h4>Overview</h4>
            <Grid container spacing={2}>
                <Grid item flexGrow={1}>
                    <DottedSum
                        icon={WorkOutlineOutlinedIcon} color="#4285F4"
                        title="Projects" subTitle="Briefed"
                        amount={person.projects_count_briefed}
                    />
                </Grid>
                <Grid item flexGrow={1}>
                    <DottedSum
                        icon={WorkOutlineOutlinedIcon} color="#27C77E"
                        title="Projects" subTitle="In Production"
                        amount={person.projects_count_in_production}
                    />
                </Grid>
                {hasBidApprovalFlow && (
                    <Grid item flexGrow={1}>
                        <DottedSum
                            icon={ArticleOutlinedIcon} color="#FFAC30"
                            title="Bid Sheets" subTitle="Pending"
                            amount={person.bids_count}
                        />
                    </Grid>
                )}
                <StrategyComponents strategy={ACCOUNTING_STRATEGY}>
                    <Grid item flexGrow={1}>
                        <DottedSum
                            icon={ReceiptOutlinedIcon} color="#E34336"
                            title="Invoices" subTitle="Outstanding"
                            amount={person.invoices_count}
                        />
                    </Grid>
                </StrategyComponents>
            </Grid>
        </>
    )
}

const DashboardMilestones = ({milestones}) => {
    return (
        <>
            <h4>Upcoming Milestones</h4>
            <List disablePadding>
                {milestones.map((milestone,i)=>
                    <div key={i}>
                        <ListItem disablePadding>
                            <ListItemButton href={`/projects/${milestone.project_id}/?milestone=${milestone.id}`} sx={{paddingLeft: 0}}>
                                <ListItemIcon>
                                    <ArticleOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    {milestone.name}
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                    </div>
                )}
            </List>
        </>
    )
}

const DashboardComments = ({comments}) => {
    return (
        <div>
            <h4>Unresolved Comments</h4>
            <div style={{maxHeight: "70vh", overflow: "auto"}}>
                {comments.map((comment, index)=>
                    <Comment key={index} comment={comment} showProjectLink/>
                )}
            </div>
        </div>
    )
}

const DashboardProjects = ({personId}) => {
    return (
        <div>
            <h4>Projects</h4>
            <Projects
                prefixedFilters={{
                    person_id: personId
                }}
                prefixedTableViewFields={
                    ["docket", "name", "eps", "status_id", "start_date", "end_date", "bid_quoted"]
                }
            />
        </div>
    )
}
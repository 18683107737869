import { useNavigate } from "react-router-dom"

import { useTheme } from "@mui/material"
import TableCell from "@mui/material/TableCell"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import Chip from '@mui/material/Chip'
import Badge from '@mui/material/Badge'
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"

import EditRoundedIcon from '@mui/icons-material/EditRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

import { CustomRow } from "@/table/CustomTable"
import { FormattedCurrency, StickyCell } from "@/common/StyledComponents"
import { FORECAST_BASE_URL, FLOAT_BASE_URL } from "@/config"
import ProtectedComponents from "@/auth/ProtectedComponents"
import DeleteRowButton from "@/common/DeleteRowButton"
import { request } from "@/Api"
import { InternalLink } from "@/common/CommonComponents"

const getTableCell = (project, field, handleEditClick, handleDelete, theme) => {
    switch(field) {
        case "docket":
            return (
                <StickyCell key={field} align="left" sx={{ background: theme.palette.background.default }}>
                    <Grid container direction="column" alignItems="center">
                        <Grid item>
                            {project.docket}
                        </Grid>
                        <Grid container direction="row" wrap="nowrap" alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Tooltip title="Open Project Details">
                                    <IconButton size="small" onClick={handleEditClick(project.id)} aria-label="edit">
                                        <Link href={`/projects/${project.id}`} color="inherit">
                                            <Badge anchorOrigin={{horizontal: "left", vertical: "top"}} color="primary" badgeContent={project.comment_count}>
                                                <EditRoundedIcon fontSize="small" />
                                            </Badge>
                                        </Link>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            { project.legacy_bid ? 
                                <Grid item md={0}>
                                    {project.google_drive_bid_file_id ? <Tooltip title={project.google_drive_bid_file_id ? "Open legacy bid sheet" : "Missing legacy bid sheet"}>
                                        <div>
                                            <IconButton disabled={!project.google_drive_bid_file_id} size="small" onClick={()=>{window.open(`https://docs.google.com/spreadsheets/d/${project.google_drive_bid_file_id}`, "_blank")}}>
                                                <RequestQuoteIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                    </Tooltip> : null}
                                </Grid>
                                : 
                                <Grid item md={0}>
                                    {project.confirmed_bid_id && (
                                        <Tooltip title={project.confirmed_bid_id ? "Open bid sheet" : "Missing integrated bid sheet"}>
                                            <div> {/* This div wrapper required to prevent error from Tooltip having dynamic child */}
                                                <InternalLink href={`/bids/${project.confirmed_bid_id}`}>
                                                    <IconButton disabled={!project.confirmed_bid_id} size="small">
                                                        <RequestQuoteIcon fontSize="small" />
                                                    </IconButton>
                                                </InternalLink>
                                            </div>
                                        </Tooltip>
                                    )}
                                </Grid>
                            }
                            { project.forecast_id || project.float_id ? 
                            <>
                                <Grid item md={0}>
                                    <Tooltip title={`Open in ${project.float_id ? "Float" : "Forecast"}`}>
                                        <IconButton size="small" onClick={()=>window.open(
                                            project.float_id ? 
                                                `${FLOAT_BASE_URL}?project=${encodeURIComponent(project.docket + " " + project.name)}`:
                                                `${FORECAST_BASE_URL}/schedule/projects/${project.forecast_id}`,
                                            "_blank")}
                                        >
                                            <CalendarMonthIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </> : null}

                            <ProtectedComponents permission={"delete_projects"}>   
                                <Grid item>
                                    <DeleteRowButton  
                                        handleDeleteConfirm={handleDelete}
                                        iconButtonSize="small" 
                                    />
                                </Grid>
                            </ProtectedComponents>
                    
                        </Grid>
                    </Grid>
                </StickyCell>
            )
            case "docket_restricted":
                return (
                    <StickyCell key={field} align="left" sx={{ background: theme.palette.background.default }}>
                        <Grid container direction="column" alignItems="center">
                            <Grid item>
                                {project.docket}
                            </Grid>
                            <Grid container direction="row" wrap="nowrap" alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Tooltip title="Open Project Details">
                                        <IconButton size="small" onClick={handleEditClick(project.id)} aria-label="edit">
                                            <Link href={`/projects/${project.id}`} color="inherit">
                                                <Badge anchorOrigin={{horizontal: "left", vertical: "top"}} color="primary" badgeContent={project.comment_count}>
                                                    <EditRoundedIcon fontSize="small" />
                                                </Badge>
                                            </Link>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>                           
                            </Grid>
                        </Grid>
                    </StickyCell>
                )
        case "client_id":
            return (
                <TableCell key={field} align="left">
                    <div style={{maxWidth:100, overflow: "hidden", textOverflow: "ellipsis"}}>
                        <span style={{whiteSpace: "nowrap"}}>{project?.client?.name}</span>
                    </div>
                </TableCell>
            )
        case "legal_entity_id":
                return (
                    <TableCell key={field} align="left">
                        <div style={{maxWidth:100, overflow: "hidden", textOverflow: "ellipsis"}}>
                            <span style={{whiteSpace: "nowrap"}}>{project?.legal_entity?.name}</span>
                        </div>
                    </TableCell>
                )
        case "status_id":
            return (
                <TableCell key={field} align="left">
                    {project.status ? (
                        <Chip
                            variant="outlined"
                            label={project.status.name}
                            sx={{ color: project.status.primary_color, borderColor: project.status.primary_color, background: project.status.secondary_color, }}
                        />
                    ) : null}
                </TableCell>
            )
        case "project_type_id":
            return (
                <TableCell key={field} align="left">
                    {project.project_type ? (
                        <Chip
                            variant="outlined"
                            label={project.project_type.name}
                        />
                    ) : null}
                </TableCell>
            )
        case "start_date":
        case "end_date":
        case "live_date":
        case "first_forecast_date":
        case "last_forecast_date":
        case "first_bill_date":
        case "last_bill_date":
            return (
                <TableCell key={field} align="left">{project[field] || ""}</TableCell>
            )
        case "signed":
            return (
                <TableCell key={field} align="center">
                    {project.signed ? <CheckCircleOutlineRoundedIcon color="success"/> : <HighlightOffRoundedIcon color="error"/>
                }</TableCell>
            )
        case "eps":
            return (
                <TableCell key={field} align="left">
                    <div style={{whiteSpace: "nowrap", maxWidth: 100, overflow: "auto"}}>
                        <span>{project.eps}</span>
                    </div>
                </TableCell>
            )
        case "persons":
            return (
                <TableCell key={field} align="left">
                    <div style={{whiteSpace: "nowrap", maxWidth: 100, overflow: "auto"}}>
                        <span>{project.persons.map(p => p.name).join(", ")}</span>
                    </div>
                </TableCell>
            )
        case "percentage_complete":
        case "percentage_billed":
            return (
                <TableCell key={field} align="left">{`${(project[field]*100).toFixed(2)}%`}</TableCell>
            )
        case "bid_quoted":
        case "forecast_revenue":
        case "bid_hard_cost":
        case "bid_gp":
        case "earned_rev":
        case "accounting_rev":
        case "accounting_cost":
        case "bid_internal_budget":
        case "float_internal_actual":
            return (
                <TableCell key={field} align="right">
                    <FormattedCurrency currency={project.currency} number={project[field]} />
                </TableCell>
            )
        case "name":
            return (
                <TableCell key={field} align="left">
                    <InternalLink href={`/projects/${project.id}`}>
                        <div style={{maxWidth:200, overflow: "hidden", textOverflow: "ellipsis"}}>
                            <span style={{whiteSpace: "nowrap"}}>{project.name}</span>
                        </div>
                    </InternalLink>
                </TableCell>
            )
        case "currency":
        default: 
            return (
                <TableCell key={field} align="left">{project[field]}</TableCell>
            )
    }
}

const ProjectRow = (props) => {
    const { item, tableFields, deleteItem } = props
    const navigate = useNavigate()
    const theme = useTheme()

    const handleEditClick = (projectId) => (event) => {
        event.preventDefault()
        navigate("/projects/"+projectId)
    }

    const handleDelete = () => {
        request.delete(`projects/${item.id}`)
        .then(_ => {
            deleteItem(item.id)
        })
    }

    return (
        <CustomRow>                    
            {tableFields.map(field => getTableCell(item, field, handleEditClick, handleDelete, theme))}
        </CustomRow>
    )
}

export default ProjectRow

import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"

import { Grid } from "@mui/material";

import { request } from "@/Api";
import { Heading } from "@/common/StyledComponents";
import PersonProfileThumbnail from "./PersonProfileThumbnail";
import { ProfileStyle } from "@/profiles_common/profileStyle";

export default function PersonProfiles(){
    const [ personProfiles, setPersonProfiles ] = useState(null) 
    const getPersonProfiles = () => {
        request.get("person-profiles")
        .then(response=>{
            setPersonProfiles(response.data)
        })
    }
    useEffect(()=>{
        getPersonProfiles()
    }, [])

    if (!personProfiles){
        return
    }
    return (
        <ProfileStyle>
            <Helmet><title>Talent - Makers Central</title></Helmet>

            <Grid container alignItems={"center"} paddingBottom={8}>
                <Heading>Talent</Heading>
            </Grid>

            <Grid container spacing={"25px"}>
                {personProfiles.map((personProfile, index) =>
                    <PersonProfileThumbnail key={index} personProfile={personProfile}/>
                )}
            </Grid>
        </ProfileStyle>
    )
}